export const US_STATES = [
  { label: 'Alabama', value: 'AL', group: 'US' },
  { label: 'Alaska', value: 'AK', group: 'US' },
  { label: 'Arizona', value: 'AZ', group: 'US' },
  { label: 'Arkansas', value: 'AR', group: 'US' },
  { label: 'California', value: 'CA', group: 'US' },
  { label: 'Colorado', value: 'CO', group: 'US' },
  { label: 'Connecticut', value: 'CT', group: 'US' },
  { label: 'Delaware', value: 'DE', group: 'US' },
  { label: 'Florida', value: 'FL', group: 'US' },
  { label: 'Georgia', value: 'GA', group: 'US' },
  { label: 'Hawaii', value: 'HI', group: 'US' },
  { label: 'Idaho', value: 'ID', group: 'US' },
  { label: 'Illinois', value: 'IL', group: 'US' },
  { label: 'Indiana', value: 'IN', group: 'US' },
  { label: 'Iowa', value: 'IA', group: 'US' },
  { label: 'Kansas', value: 'KS', group: 'US' },
  { label: 'Kentucky', value: 'KY', group: 'US' },
  { label: 'Louisiana', value: 'LA', group: 'US' },
  { label: 'Maine', value: 'ME', group: 'US' },
  { label: 'Maryland', value: 'MD', group: 'US' },
  { label: 'Massachusetts', value: 'MA', group: 'US' },
  { label: 'Michigan', value: 'MI', group: 'US' },
  { label: 'Minnesota', value: 'MN', group: 'US' },
  { label: 'Mississippi', value: 'MS', group: 'US' },
  { label: 'Missouri', value: 'MO', group: 'US' },
  { label: 'Montana', value: 'MT', group: 'US' },
  { label: 'Nebraska', value: 'NE', group: 'US' },
  { label: 'Nevada', value: 'NV', group: 'US' },
  { label: 'New Hampshire', value: 'NH', group: 'US' },
  { label: 'New Jersey', value: 'NJ', group: 'US' },
  { label: 'New Mexico', value: 'NM', group: 'US' },
  { label: 'New York', value: 'NY', group: 'US' },
  { label: 'North Carolina', value: 'NC', group: 'US' },
  { label: 'North Dakota', value: 'ND', group: 'US' },
  { label: 'Ohio', value: 'OH', group: 'US' },
  { label: 'Oklahoma', value: 'OK', group: 'US' },
  { label: 'Oregon', value: 'OR', group: 'US' },
  { label: 'Pennsylvania', value: 'PA', group: 'US' },
  { label: 'Puerto Rico', value: 'PR', group: 'US' },
  { label: 'Rhode Island', value: 'RI', group: 'US' },
  { label: 'South Carolina', value: 'SC', group: 'US' },
  { label: 'South Dakota', value: 'SD', group: 'US' },
  { label: 'Tennessee', value: 'TN', group: 'US' },
  { label: 'Texas', value: 'TX', group: 'US' },
  { label: 'Utah', value: 'UT', group: 'US' },
  { label: 'Vermont', value: 'VT', group: 'US' },
  { label: 'Virginia', value: 'VA', group: 'US' },
  { label: 'Washington', value: 'WA', group: 'US' },
  { label: 'West Virginia', value: 'WV', group: 'US' },
  { label: 'Wisconsin', value: 'WI', group: 'US' },
  { label: 'Wyoming', value: 'WY', group: 'US' },
];

export const CANADA_STATES = [
  { label: 'Alberta', value: 'AB', group: 'CANADA' },
  { label: 'British Columbia', value: 'BC', group: 'CANADA' },
  { label: 'Manitoba', value: 'MB', group: 'CANADA' },
  { label: 'New Brunswick', value: 'NB', group: 'CANADA' },
  { label: 'Newfoundland and Labrador', value: 'NL', group: 'CANADA' },
  { label: 'Northwest Territories', value: 'NT', group: 'CANADA' },
  { label: 'Nova Scotia', value: 'NS', group: 'CANADA' },
  { label: 'Nunavut', value: 'NU', group: 'CANADA' },
  { label: 'Ontario', value: 'ON', group: 'CANADA' },
  { label: 'Prince Edward Island', value: 'PE', group: 'CANADA' },
  { label: 'Quebec', value: 'QC', group: 'CANADA' },
  { label: 'Saskatchewan', value: 'SK', group: 'CANADA' },
  { label: 'Yukon', value: 'YT', group: 'CANADA' },
];
