import AppColors from 'AppColors';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';
import { HeaderGroup } from './utils';
import { RowRenderFunction, DataTableColumn } from './interfaces';
import { StyledTableCell } from './DataTable.styled';
import { SxProps, Theme } from '@mui/material/styles';
import { Collapse, IconButton, Table, TableBody, TableContainer, TableHead } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function DataTableBodyRow<DataType extends Record<string, any>>({
  row,
  rowIndex,
  isSelected,
  onRowClick,
  hasSelectors,
  onSelect,
  headerGroup,
  onTextCellClick,
  hasAnyAction,
  Row,
  sx: sxProp,
  renderActions,
  isCollapsible,
}: {
  row: DataType;
  rowIndex: number;
  isSelected: (row: DataType) => boolean;
  onRowClick?: (row: DataType) => void;
  hasSelectors?: boolean;
  onSelect: (row: DataType) => void;
  headerGroup: HeaderGroup;
  onTextCellClick?: (row: DataType, col: DataTableColumn<any>) => void;
  hasAnyAction: boolean;
  Row?: (row: DataType, rowIndex: number) => RowRenderFunction;
  sx?: (row: DataType, rowIndex: number) => SxProps<Theme>;
  renderActions?: (row: DataType, rowIndex: number) => React.ReactNode;
  isCollapsible?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const isItemSelected = isSelected(row);
  const RowRender = Row ? Row(row, rowIndex) : TableRow;
  const sx = sxProp ? sxProp(row, rowIndex) : null;

  const collapsibleHeader =
    row.collapsibleData?.length > 0 &&
    Object.keys(row.collapsibleData[0])
      .map((s: string) => ({
        id: s,
        label: s.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase()),
      }))
      .sort((a, b) => b.label.localeCompare(a.label));

  return (
    <>
      <RowRender
        hover={!sx}
        role="checkbox"
        selected={isItemSelected}
        onClick={() => onRowClick?.(row)}
        sx={{
          ...sx,
          ...(onRowClick && {
            '&:hover': {
              cursor: 'pointer',
              '& *': {
                cursor: 'pointer',
              },
            },
          }),
        }}
        data-testid="DataTableBodyRow"
      >
        {hasSelectors && (
          <TableCell
            padding="checkbox"
            sx={{
              borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              onSelect(row);
            }}
          >
            <Checkbox color="primary" checked={isItemSelected} />
          </TableCell>
        )}
        {isCollapsible && (
          <TableCell
            padding="checkbox"
            sx={{
              borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
            }}
          >
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {headerGroup.headers.map((col, columnIndex) => (
          <StyledTableCell
            focusHighlight
            key={`${rowIndex}-${columnIndex}`}
            sx={{
              borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
              minWidth: col.minWidth,
              maxWidth: col.maxWidth,
              width: col.width,
            }}
            data-testid={col.testId ? `${col.testId}_${rowIndex}` : undefined}
          >
            <Box
              onClick={() => onTextCellClick?.(row, col)}
              sx={{ width: 'fit-content', color: 'inherit', cursor: 'default', ...col.sx }}
            >
              {col.accessor ? col.accessor(row, rowIndex) : row[col.id.toString()]}
            </Box>
          </StyledTableCell>
        ))}
        {hasAnyAction && (
          <TableCell sx={{ borderBottom: `1px solid ${AppColors.AAA_LIGHT_NAVY}` }}>
            {renderActions?.(row, rowIndex)}
          </TableCell>
        )}
      </RowRender>
      {isCollapsible && row.collapsibleData?.length > 0 && (
        <RowRender>
          <TableCell
            sx={{ paddingY: 0, paddingLeft: 6, paddingRight: 0 }}
            colSpan={
              headerGroup.headers.length + (hasSelectors ? 1 : 0) + (hasAnyAction ? 1 : 0) + 1
            }
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableContainer sx={{ maxHeight: 200 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {collapsibleHeader &&
                        collapsibleHeader.map(
                          (
                            header: {
                              id: string;
                              label: string;
                            },
                            i: number,
                          ) => (
                            <TableCell
                              key={i}
                              sx={{
                                border: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
                              }}
                            >
                              {header.label}
                            </TableCell>
                          ),
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.collapsibleData?.map((data: Record<string, any>, i: number) => (
                      <TableRow key={i}>
                        {collapsibleHeader &&
                          collapsibleHeader.map((value: any, j: number) => (
                            <TableCell
                              key={j}
                              sx={{
                                border: `1px solid ${AppColors.AAA_LIGHT_NAVY}`,
                              }}
                            >
                              {data[value.id]}
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </TableCell>
        </RowRender>
      )}
    </>
  );
}
