import { DynamicForm } from 'components/Dynamic/DynamicForm';
import FormDivider from 'components/Form/FormDivider';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useInformationFields,
  useSurveyInfoFields,
  contactFields,
  splitFinancialRulesFields,
} from '../ProgramPageConfig';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hookForm: UseFormReturn<any>;
  isEditMode?: boolean;
  isCreationMode?: boolean;
}

const ProgramGeneralInfoForm = ({ hookForm, isEditMode, isCreationMode = false }: Props) => {
  const { control } = hookForm;
  const watchSurvey = useWatch({ control, name: 'survey' });
  const surveyInfoFields = useSurveyInfoFields({ isSurvey: watchSurvey });

  const informationFields = useInformationFields(isCreationMode);
  return (
    <>
      <FormDivider label="Information" sx={{ mt: 1, mb: 3 }} />
      <DynamicForm hookForm={hookForm} isEditMode={isEditMode} fields={informationFields} />

      <FormDivider label="Survey Info" sx={{ mt: 2, mb: 3 }} />
      <DynamicForm hookForm={hookForm} isEditMode={isEditMode} fields={surveyInfoFields} />

      <FormDivider label="Inbound Contact Center Details" sx={{ mt: 2, mb: 3 }} />
      <DynamicForm hookForm={hookForm} isEditMode={isEditMode} fields={contactFields} />

      <FormDivider label="Split Financial Configuration" sx={{ mt: 2, mb: 3 }} />
      <DynamicForm hookForm={hookForm} isEditMode={isEditMode} fields={splitFinancialRulesFields} />
    </>
  );
};

export default ProgramGeneralInfoForm;
