import { DataTableColumn } from 'components/DataTable';
import { ReportData } from 'features/Reports/types';
import { ReactNode } from 'react';
import { convertToMMYYYY } from 'utils/convertDate';

export const reportsColumns = (
  downloadReport: (row: ReportData, index: number) => ReactNode,
): DataTableColumn<ReportData>[] => [
  {
    id: 'reportName',
    label: 'Report Name',
  },
  {
    id: 'monthYear',
    label: 'Month/Year',
    accessor: (row) => row.monthYear && convertToMMYYYY(row.monthYear),
  },
  {
    id: 'reports' as keyof ReportData,
    label: 'Actions',
    accessor: downloadReport,
  },
];
