import { useForm, useWatch } from 'react-hook-form';
import { useGetCaseGeneralInfoByUUID } from '../../GeneralInfo/GeneralInfoQuery';
import { useEffect } from 'react';
import { GENERAL_INFO_PERMISSION } from '../../GeneralInfo/config';
import { useFieldPermission } from 'auth';
import { DynamicFieldType } from 'components/Dynamic';
import { PaymentResponsibleValues } from 'api/cases/enums/PaymentResponsible';

type Props = {
  caseUUID: string;
  isEditMode: boolean;
};

export function useMileagesForm({ caseUUID, isEditMode }: Props) {
  const { data: caseGeneralInfoData } = useGetCaseGeneralInfoByUUID(caseUUID, true);

  const mileagesHookForm = useForm({
    defaultValues: {
      mileageOverride: caseGeneralInfoData?.mileageOverride || '',
      paymentResponsible: caseGeneralInfoData?.paymentResponsible ?? 'CLIENT_PAID',
    },
  });

  useEffect(() => {
    if (caseGeneralInfoData) {
      mileagesHookForm.reset({
        mileageOverride: caseGeneralInfoData.mileageOverride || '',
        paymentResponsible: caseGeneralInfoData.paymentResponsible || 'CLIENT_PAID',
      });
    }
  }, [caseGeneralInfoData, mileagesHookForm]);

  const mileagesFields = useFieldPermission({
    fields: [
      {
        name: 'paymentResponsible',
        label: 'Payment Responsible',
        type: DynamicFieldType.SELECT,
        select: {
          options: PaymentResponsibleValues.map((item) => ({ label: item, value: item })),
        },
      },
      {
        name: 'mileageOverride',
        label: 'Override/Overage Mileage',
      },
    ],
    table: GENERAL_INFO_PERMISSION,
    isEditMode,
  });

  return {
    mileagesHookForm,
    mileagesFields,
  };
}
