/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';
import queryString from 'query-string';
import { forceLogoutAndRedirect, getCurrentTokenAndExpired } from 'auth/domain-auth/helpers';

async function authenticatedRequest<R>(baseConfig: AxiosRequestConfig) {
  let bearerToken = '';

  try {
    const { token, isTokenExpired } = await getCurrentTokenAndExpired();

    bearerToken = token;

    if (isTokenExpired) {
      forceLogoutAndRedirect();
    }
  } catch (e) {
    forceLogoutAndRedirect();
  }

  const config: AxiosRequestConfig = {
    ...baseConfig,
    headers: { ...baseConfig.headers, Authorization: `Bearer ${bearerToken}` },
    paramsSerializer: (params) => {
      return queryString.stringify(params, { arrayFormat: 'bracket' });
    },
  };

  return axios.request<R>(config).then((res) => res.data);
}

export const BaseAPI = {
  get: <R>(url: string, config?: AxiosRequestConfig) =>
    authenticatedRequest<R>({ method: 'GET', url, ...config }),
  delete: <R>(url: string, config?: AxiosRequestConfig) =>
    authenticatedRequest<R>({ method: 'DELETE', url, ...config }),
  post: <R>(url: string, data?: any, config?: AxiosRequestConfig) =>
    authenticatedRequest<R>({ method: 'POST', url, data, ...config }),
  put: <R>(url: string, data?: any, config?: AxiosRequestConfig) =>
    authenticatedRequest<R>({ method: 'PUT', url, data, ...config }),

  patch: <R>(url: string, data?: any, config?: AxiosRequestConfig) =>
    authenticatedRequest<R>({ method: 'PATCH', url, data, ...config }),
};
