import Box from '@mui/material/Box';
import DataTable, { DataTableColumn } from 'components/DataTable';
import FormDivider from 'components/Form/FormDivider';
import { convertUTCToDateTimeString } from 'utils/convertDate';
import { useCaseFinancialsConfig } from './config';
import { BillingRequestStatusHistoryViewDto } from 'api/invoices';
import { useMemo } from 'react';
import { PaymentRequestViewDto } from 'api/paymentRequests/interfaces';

type Props = {
  billingStatus: BillingRequestStatusHistoryViewDto[];
  billingRequestNumber: number;
  paymentRequest?: PaymentRequestViewDto;
};

type DataTableType = {
  number: number;
  status: string;
  description?: string;
  createdAtDateTime: string;
  createdByUserId: string;
};

const columns: DataTableColumn<DataTableType>[] = [
  {
    id: 'status',
    label: 'Billing / Payment',
    testId: 'billing-status-description',
    accessor: (row) =>
      // eslint-disable-next-line no-nested-ternary
      row.status
        ? row.description
          ? `${row.status} - ${row.description}`
          : row.status
        : row.description
        ? row.description
        : '-',
  },
  {
    id: 'number',
    label: 'Bill Request / Payment Number',
    testId: 'billing-request-number',
  },
  {
    id: 'createdAtDateTime',
    label: 'Date & Time',
    accessor: (row) => convertUTCToDateTimeString(row.createdAtDateTime),
    testId: 'billing-date-time',
  },
  {
    id: 'createdByUserId',
    label: 'Created By',
    testId: 'billing-created-by',
  },
];

export default function BillingStatus({
  billingStatus,
  billingRequestNumber,
  paymentRequest,
}: Props) {
  const { caseFinancialViewTable } = useCaseFinancialsConfig();
  const canReadBillingStatus = caseFinancialViewTable?.fields?.['billingStatus']?.read;

  const billingData: DataTableType[] = useMemo(() => {
    const data: DataTableType[] = billingStatus.map((item) => ({
      ...item,
      status: item.status?.toString(),
      number: billingRequestNumber,
    }));
    if (paymentRequest) {
      data.push({
        number: paymentRequest.paymentGroupNumber,
        status: paymentRequest.status,
        createdAtDateTime: paymentRequest.createdAtDateTime,
        createdByUserId: paymentRequest.createdByUserId,
      });
    }
    return data;
  }, [billingRequestNumber, billingStatus, paymentRequest]);

  return (
    <>
      {canReadBillingStatus && (
        <>
          <FormDivider label="Processing Status" sx={{ mb: 2 }} />
          <Box mx={-4} mb={2}>
            <DataTable columns={columns} rows={billingData} />
          </Box>
        </>
      )}
    </>
  );
}
