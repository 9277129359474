import { useMemo } from 'react';
import * as yup from 'yup';
import { DynamicField, DynamicFieldType } from 'components/Dynamic';
import { ProgramData } from './ProgramProvider';
import { SurveyTypes } from 'api/setup/manageClientApi';

interface Props {
  isSurvey: boolean;
}

export const useInformationFields = (isCreationMode: boolean): DynamicField<ProgramData>[] => {
  return useMemo(() => {
    const InformationFields: DynamicField<ProgramData>[] = [
      { label: 'Client ID', name: 'rapClientId', isReadOnly: true },
      { label: 'Client Name', name: 'clientName', isReadOnly: true },
      { label: 'Program ID', name: 'rapProgramId', required: true, isReadOnly: !isCreationMode },
      { label: 'Program Name', name: 'programName', required: true },
      { label: 'Program Abbreviation', name: 'abbreviatedName' },
      {
        label: 'RAP Program Code',
        name: 'rapProgramCode',
        required: true,
        isReadOnly: !isCreationMode,
      },
    ];

    return InformationFields;
  }, [isCreationMode]);
};

const surveyTypeOptions = [
  { label: SurveyTypes.EMAIL, value: SurveyTypes.EMAIL },
  { label: SurveyTypes.PHONE, value: SurveyTypes.PHONE },
  { label: SurveyTypes.SMS, value: SurveyTypes.SMS },
];

export const useSurveyInfoFields = ({ isSurvey }: Props) => {
  return useMemo(() => {
    const surveyInfoFields: DynamicField<ProgramData>[] = [
      { label: 'Survey', name: 'survey', type: DynamicFieldType.CHECKBOX },
      {
        label: 'Survey Type',
        name: 'surveyType',
        type: DynamicFieldType.SELECT,
        select: { options: surveyTypeOptions },
        isReadOnly: !isSurvey,
        required: isSurvey,
      },
      {
        label: 'Survey Administrator',
        name: 'surveyAdministrator',
        isReadOnly: !isSurvey,
        required: isSurvey,
      },
    ];

    return surveyInfoFields;
  }, [isSurvey]);
};

export const surveyInfoFields: DynamicField<ProgramData>[] = [
  { label: 'Survey', name: 'survey', type: DynamicFieldType.CHECKBOX },
  {
    label: 'Survey Type',
    name: 'surveyType',
    type: DynamicFieldType.SELECT,
    select: { options: surveyTypeOptions },
  },
  { label: 'Survey Administrator', name: 'surveyAdministrator' },
];

export const splitFinancialRulesFields: DynamicField<ProgramData>[] = [
  { label: 'Long Tow Mileage Threshold', name: 'longTowMileageThreshold' },
];

export const GeneralInfoValidationSchema = yup.object({
  rapProgramId: yup
    .number()
    .positive()
    .integer()
    .typeError('Program ID must be a number')
    .required()
    .label('Program ID'),
  rapProgramCode: yup
    .number()
    .positive()
    .integer()
    .typeError('RAP program Code must be a number')
    .required()
    .label('RAP Program Code'),
  programName: yup.string().required().label('Program Name'),
  survey: yup.boolean(),
  surveyType: yup.string().when('survey', {
    is: true,
    then: yup.string().required('Survey Type is required'),
    otherwise: yup.string(),
  }),
  surveyAdministrator: yup.string().when('survey', {
    is: true,
    then: yup.string().required('Survey Administrator is required'),
    otherwise: yup.string(),
  }),
});

export const contactFields: DynamicField<ProgramData>[] = [
  { label: 'Contact Center Partner', name: 'contactCenterPartner', isReadOnly: true },
  { label: 'Customer/Guest Call Number', name: 'customerCallNumber' },
  { label: 'AAA Assigned DID', name: 'aaaAssignedDid', isReadOnly: true },
  { label: "Club's Call-In Number", name: 'clubCallInNumber' },
];
