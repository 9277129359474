/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import { DefaultValues, UseFormReturn } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import DataTable, { DataTableColumn } from 'components/DataTable';
import { CRUDNavigators } from 'components/CRUDNavigator';
import { DynamicQueryFieldType } from 'components/Dynamic/DynamicSearchBox/types';
import { DynamicSearchBox, DynamicValue } from 'components/Dynamic';
import { EmptySearchCard, ErrorSearchCard, LoaderCard } from 'components/Cards';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import SubMenuButton, { SubMenuItem } from 'components/SubMenuButton/SubMenuButton';
import { PageableProviderContextType } from '../types';

interface LocationState {
  notifications?: NotificationType;
}

interface Props<DtoType, DataType = DtoType, SearchType extends DynamicValue = DynamicValue> {
  context: PageableProviderContextType<DtoType, DataType>;
  navigators?: CRUDNavigators;
  searchFields: DynamicQueryFieldType<SearchType>[];
  searchDefaultValues?: DefaultValues<SearchType>;
  searchBoxTitle?: string;
  tableColumns: DataTableColumn<DataType>[];
  name: string;
  /**
   * Callback to get UUID from data
   */
  getUUID?: (data: DataType) => string;
  hasSelectors?: boolean;
  onRowClick?: (data: DataType) => void;
  hasHeaderButton?: boolean;
  canCreate?: boolean;
  useFormReturn?: UseFormReturn<SearchType>;
  subMenuActions?: SubMenuItem[];
  onSelect?: (items: string[]) => void;
  indexName?: string;
  /**
   * Call be called after reset actions.
   */
  onReset?: (useFormReturn?: UseFormReturn<SearchType>) => void;

  isCollapsible?: boolean;
}

/**
 * component to wrap all List methods and views
 */
export function CRUDPageableList<
  DtoType,
  DataType = DtoType,
  SearchType extends DynamicValue = DynamicValue,
>({
  context,
  navigators,
  getUUID,
  searchFields,
  searchDefaultValues,
  searchBoxTitle,
  tableColumns,
  name,
  hasSelectors,
  onRowClick: onRowClickProp,
  hasHeaderButton = true,
  canCreate = true,
  useFormReturn,
  subMenuActions,
  indexName,
  onSelect,
  onReset,
  isCollapsible,
}: Props<DtoType, DataType, SearchType>) {
  const {
    requestAll,
    getAll: { isRunning, isEmpty, isDone, data, isError },
    paginationOptions,
    sortOptions,
    notifications,
    setNotifications,
    clearQueryCache,
  } = context;

  useEffect(() => {
    return () => {
      // Reset all data on unmount
      clearQueryCache();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const { notifications: stateNotifications } = (state as LocationState) || {};

  const onRowClick = useCallback(
    (data: any) => {
      if (onRowClickProp) {
        onRowClickProp(data);
      } else if (navigators) {
        const uuid = getUUID ? getUUID(data) : data.uuid || '';
        navigators.toIndividualScreen(uuid);
      }
    },
    [navigators, getUUID, onRowClickProp],
  );

  useEffect(() => {
    if (stateNotifications) {
      setNotifications(stateNotifications);

      // Remove notifications from the location state
      navigate(location.pathname, { replace: true });
    }
  }, [setNotifications, stateNotifications, navigate, pathname]);

  return (
    <Stack spacing="10px" flexGrow={1}>
      <DynamicSearchBox
        data-testid={`DynamicSearchBox-${name}`}
        fields={searchFields}
        defaultValues={searchDefaultValues}
        onSubmit={requestAll}
        onHeaderButtonClick={navigators?.toCreateScreen}
        headerButtonLabel={hasHeaderButton ? `New ${name}` : undefined}
        canCreate={canCreate}
        useFormReturn={useFormReturn}
        title={searchBoxTitle}
        name={name}
        onReset={onReset}
      />
      <Stack flexGrow={1}>
        {isRunning && <LoaderCard label={`Loading ${name}s ...`} sx={{ flexGrow: 1 }} />}

        {isEmpty && <EmptySearchCard sx={{ flexGrow: 1 }} />}

        {isError && <ErrorSearchCard sx={{ flexGrow: 1 }} />}

        {isDone && (
          <Box component={Paper}>
            {subMenuActions && (
              <Box display="flex" justifyContent="flex-end" padding={2}>
                <SubMenuButton label="Actions" menu={subMenuActions || []} />
              </Box>
            )}
            <DataTable<any>
              data-testid={DataTable.name}
              hasSelectors={hasSelectors}
              indexName={indexName ?? 'uuid'}
              onRowClick={onRowClick}
              columns={tableColumns}
              rows={data}
              paginationOptions={paginationOptions}
              sortOptions={sortOptions}
              stickyHeader
              onSelect={onSelect}
              isCollapsible={isCollapsible}
            />
          </Box>
        )}
      </Stack>
      <NotificationSnackbar
        clearNotification={() => setNotifications(undefined)}
        notification={notifications}
      />
    </Stack>
  );
}
