import { CRUDNavigator } from 'components/CRUDNavigator';
import AuditLogsPageList from './AuditLogsPageList';
import AuditLogsProvider from './AuditLogsProvider';

const AuditLogsPage = () => {
  return (
    <AuditLogsProvider>
      <CRUDNavigator
        components={{
          Main: AuditLogsPageList,
        }}
      />
    </AuditLogsProvider>
  );
};

export default AuditLogsPage;
