import {
  CRUDPageableProvider,
  PageableProviderData,
  usePageableContextData,
} from 'components/CRUDPageable';
import { CRUDPageableReactQuery } from 'hooks/useReactQueryHelpers';
import { PropsWithChildren } from 'react';
import { AuditLogsData, AuditLogsDto } from './types';
import { manageAuditLogsApi } from 'api/auditLogs';

const mapDtoToData = (dto: AuditLogsDto): AuditLogsData => dto;

export const AuditLogsQuery = CRUDPageableReactQuery<AuditLogsDto, AuditLogsData>(
  'QUERY_AUDIT_LOGS',
  manageAuditLogsApi,
  mapDtoToData,
);

export function useAuditLogsContext() {
  return usePageableContextData<AuditLogsDto, AuditLogsData>();
}

const initialState: Partial<PageableProviderData> = {
  orderBy: 'eventDateTime',
  order: 'desc',
};

export default function AuditLogsProvider({ children }: PropsWithChildren) {
  return (
    <CRUDPageableProvider queries={AuditLogsQuery} initialState={initialState} reQueryOnMount>
      {children}
    </CRUDPageableProvider>
  );
}
