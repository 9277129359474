import { useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import SubMenuButton from 'components/SubMenuButton';
import { SubMenuItem } from 'components/SubMenuButton/SubMenuButton';
import { NotificationType } from 'components/Notifications';
import DialogConfirmation from 'components/DialogConfirmation';
import { DynamicField, DynamicFieldType, DynamicForm, DynamicValue } from 'components/Dynamic';
import { CaseData } from './interfaces/CaseData';
import {
 buildErrorCodesArray,
 CaseBulkActionsEnum,
 getPopupMessage,
 getCasesCantCancel,
 getCasesCantClose,
 getCasesCantIgnore,
 getCasesCantOverride,
 getCasesCantUnCancel,
 getCasesCantAttachToBilling,
} from './helpers';
import { useExecuteBulkAction, useGetCaseAttachableBillingRequests } from './queries';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { yupResolver } from '@hookform/resolvers/yup';
import { useClaimOverrideConfig } from './CaseDetails/CaseFinancials/useClaims';

type Props = {
 selectedCases: string[];
 setNotifications: (value: NotificationType) => void;
 setSelectedCases: React.Dispatch<React.SetStateAction<string[]>>;
 data: CaseData[];
 onBulkActionSubmit: (caseIds: string[]) => void;
};

const ignoreErrorsValidationSchema = Yup.object().shape({
 errorCodes: Yup.array()
  .of(Yup.string())
  .required('Error codes')
  .min(1, 'Select one error code at least'),
 reason: Yup.string().required().label('Reason'),
});

const attachToBillingValidationSchema = Yup.object().shape({
 billingRequestUuid: Yup.string()
  .required('Billing Request')
  .min(1, 'Select one billing request at least'),
});

export function CasePageListBulkActions({
 selectedCases,
 data,
 setNotifications,
 setSelectedCases,
 onBulkActionSubmit,
}: Props) {
 const { mutateAsync: executeBulkAction, isError } = useExecuteBulkAction();
 const [showErrorCodeDialog, setShowErrorCodeDialog] = useState(false);
 const [showOverrideDialog, setShowOverrideDialog] = useState(false);
 const [showAttachToBillingDialog, setShowAttachToBillingDialog] = useState(false);
 const { data: brData } = useGetCaseAttachableBillingRequests(selectedCases[0], true);
 const errorCasesList = buildErrorCodesArray(selectedCases, data);
 const selectedCasesData = data.filter((data) => selectedCases.includes(data.uuid));
 const selectedCasesCantOverride = getCasesCantOverride(selectedCasesData);
 const selectedCasesCantAttachToBilling = getCasesCantAttachToBilling(selectedCasesData);
 const selectedCasesCantClose = getCasesCantClose(selectedCasesData);
 const selectedCasesCantCancel = getCasesCantCancel(selectedCasesData);
 const selectedCasesCantUnCancel = getCasesCantUnCancel(selectedCasesData);
 const selectedCasesCantIgnore = getCasesCantIgnore(selectedCasesData);

 const { claimOverrideFields, claimOverrideValidationSchema } = useClaimOverrideConfig();

 const ignoreErrorsForm = useForm({
  defaultValues: {
   errorCodes: [],
   reason: '',
  },
  resolver: yupResolver(ignoreErrorsValidationSchema),
  mode: 'onChange',
 });

 const overrideForm = useForm({
  resolver: yupResolver(claimOverrideValidationSchema),
  mode: 'onChange',
 });

 const ignoreErrorsFields: DynamicField<DynamicValue>[] = [
  {
   name: 'errorCodes',
   label: 'Error Codes',
   colspan: 12,
   type: DynamicFieldType.SELECT,
   select: {
    options: errorCasesList,
    multiple: true,
    type: 'chip',
   },
  },
  {
   name: 'reason',
   label: 'Reason',
   multiline: true,
   colspan: 12,
   type: DynamicFieldType.TEXT,
   required: true,
   rows: 3,
  },
 ];

 const attachToBillingFields: DynamicField<DynamicValue>[] = [
  {
   name: 'billingRequestUuid',
   label: 'Billing Request',
   colspan: 12,
   type: DynamicFieldType.SELECT,
   select: {
    options: brData,
   },
  },
 ];

 const attachToBillingForm = useForm({
  defaultValues: {
   billingRequestUuid: '',
  },
  resolver: yupResolver(attachToBillingValidationSchema),
  mode: 'onChange',
 });

 const { permissions: clsPermissions } = useRapCoreFeaturePermissions('forceCls');

 const handleCancelCasesBulkAction = async () => {
  try {
   const rbpCaseUuidsToCancel = selectedCases.filter(
    (c) => !selectedCasesCantCancel.find((sc) => sc.uuid === c),
   );
   await executeBulkAction({
    rbpCaseUuids: rbpCaseUuidsToCancel,
    action: CaseBulkActionsEnum.CANCEL,
   });
   setNotifications({
    type: 'success',
    name: 'Success',
    message: 'Cancelling selected cases initiated successfully',
   });
   setSelectedCases([]);
   onBulkActionSubmit(rbpCaseUuidsToCancel);
  } catch (error: any) {
   console.error(error.response.data.message);
   setNotifications({
    type: 'error',
    name: 'Error',
    message: 'Cancelling selected cases initiation failed',
   });
  }
 };

 const handleUncancelCasesBulkAction = async () => {
  try {
   const rbpCaseUuidsToUnCancel = selectedCases.filter(
    (c) => !selectedCasesCantUnCancel.find((sc) => sc.uuid === c),
   );
   await executeBulkAction({
    rbpCaseUuids: rbpCaseUuidsToUnCancel,
    action: CaseBulkActionsEnum.UN_CANCEL,
   });
   setNotifications({
    type: 'success',
    name: 'Success',
    message: 'Uncancelling selected cases initiated successfully',
   });
   setSelectedCases([]);
   onBulkActionSubmit(rbpCaseUuidsToUnCancel);
  } catch (error: any) {
   console.error(error.response.data.message);
   setNotifications({
    type: 'error',
    name: 'Error',
    message: 'Uncancelling selected cases initiation failed',
   });
  }
 };

 const handleIgnoreErrorsBulkAction = async () => {
  try {
   await ignoreErrorsForm.trigger();

   if (ignoreErrorsForm.formState.isValid) {
    const rbpCaseUuidsToIgnoreErrors = selectedCases.filter(
     (c) => !selectedCasesCantIgnore.find((sc) => sc.uuid === c),
    );
    await executeBulkAction({
     rbpCaseUuids: rbpCaseUuidsToIgnoreErrors,
     action: CaseBulkActionsEnum.IGNORE_ERROR,
     parameters: {
      errorCodes: ignoreErrorsForm.getValues().errorCodes.join(', '),
      reason: ignoreErrorsForm.getValues().reason,
     },
    });
    setNotifications({
     type: 'success',
     name: 'Success',
     message: 'Ignoring errors of selected cases initiated successfully',
    });
    setSelectedCases([]);
    onBulkActionSubmit(rbpCaseUuidsToIgnoreErrors);
   }
  } catch (error: any) {
   console.error(error.response.data.message);
   setNotifications({
    type: 'error',
    name: 'Error',
    message: 'Ignoring errors of selected cases initiation failed',
   });
  }
 };

 const handleCloseCasesBulkAction = async () => {
  try {
   const rbpCaseUuidsToClose = selectedCases.filter(
    (c) => !selectedCasesCantClose.find((sc) => sc.uuid === c),
   );
   await executeBulkAction({
    rbpCaseUuids: rbpCaseUuidsToClose,
    action: CaseBulkActionsEnum.CLOSE,
   });
   setNotifications({
    type: 'success',
    name: 'Success',
    message: 'Closing selected cases initiated successfully',
   });
   setSelectedCases([]);
   onBulkActionSubmit(rbpCaseUuidsToClose);
  } catch (error: any) {
   console.error(error.response.data.message);
   setNotifications({
    type: 'error',
    name: 'Error',
    message: 'Closing selected cases initiation failed',
   });
  }
 };

 const handleOverrideCasesRejectsBulkAction = async () => {
  try {
   const rbpCaseUuidsToOverride = selectedCases.filter(
    (c) => !selectedCasesCantOverride.find((sc) => sc.uuid === c),
   );
   await overrideForm.trigger();

   if (overrideForm.formState.isValid) {
    await executeBulkAction({
     rbpCaseUuids: rbpCaseUuidsToOverride,
     action: CaseBulkActionsEnum.CLAIM_OVERRIDE,
     parameters: {
      claimStatus: overrideForm.getValues().claimStatus,
      ignoreReason: overrideForm.getValues().ignoreReason,
     },
    });
    setNotifications({
     type: 'success',
     name: 'Success',
     message: 'Overriding of selected cases initiated successfully',
    });
    setSelectedCases([]);
    onBulkActionSubmit(rbpCaseUuidsToOverride);
   }
  } catch (error: any) {
   console.error(error.response.data.message);
   setNotifications({
    type: 'error',
    name: 'Error',
    message: 'Overriding of selected cases initiation failed',
   });
  }
 };

 const handleAttachToBillingBulkAction = async () => {
  try {
   const rbpCaseUuidsToAttachToBilling = selectedCases.filter(
    (c) => !selectedCasesCantAttachToBilling.find((sc) => sc.uuid === c),
   );
   await attachToBillingForm.trigger();

   if (attachToBillingForm.formState.isValid) {
    await executeBulkAction({
     rbpCaseUuids: rbpCaseUuidsToAttachToBilling,
     action: CaseBulkActionsEnum.ATTACH_TO_BILLING,
     parameters: {
      billingRequestUuid: attachToBillingForm.getValues().billingRequestUuid,
     },
    });
    setNotifications({
     type: 'success',
     name: 'Success',
     message: 'Attaching selected cases to Billing Request initiated successfully',
    });
    setSelectedCases([]);
    onBulkActionSubmit(rbpCaseUuidsToAttachToBilling);
   }
  } catch (error: any) {
   console.error(error.response.data.message);
   setNotifications({
    type: 'error',
    name: 'Error',
    message: 'Attaching selected cases to Billing Request initiation failed',
   });
  }
 };

 const subMenuActions: SubMenuItem[] = [
  {
   label: 'Cancel Cases',
   title: 'Cancel Cases',
   content: getPopupMessage(
    'Cancel',
    selectedCases.filter((c) => !selectedCasesCantCancel.find((sc) => sc.uuid === c)),
    selectedCasesCantCancel,
    'RBP status must NOT be BILLING OK nor CANCELLED',
   ),
   labelConfirm: 'Cancel cases',
   labelCancel: 'Close',
   onClick: handleCancelCasesBulkAction,
   disabled: !selectedCases || selectedCases.length < 1,
  },
  {
   label: 'Uncancel Cases',
   title: 'Uncancel Cases',
   content: getPopupMessage(
    'Uncancel',
    selectedCases.filter((c) => !selectedCasesCantUnCancel.find((sc) => sc.uuid === c)),
    selectedCasesCantUnCancel,
    'RBP status must be CANCELLED',
   ),
   labelConfirm: 'Uncancel cases',
   labelCancel: 'Close',
   onClick: handleUncancelCasesBulkAction,
   disabled: !selectedCases || selectedCases.length < 1,
  },
  {
   label: 'Close Cases',
   title: 'Close Cases',
   content: getPopupMessage(
    'Close',
    selectedCases.filter((c) => !selectedCasesCantClose.find((sc) => sc.uuid === c)),
    selectedCasesCantClose,
    "RBP status must  be ENRICHED and call status NOT be 'CLS'",
   ),
   labelConfirm: 'Close cases',
   labelCancel: 'Cancel',
   onClick: handleCloseCasesBulkAction,
   disabled: !selectedCases || selectedCases.length < 1 || !clsPermissions?.update,
  },
  {
   label: 'Ignore Errors',
   onClick: () => setShowErrorCodeDialog(true),
   hasCustomAction: true,
   disabled: !selectedCases || selectedCases.length < 1 || errorCasesList.length < 1,
  },
  {
   label: 'Override Rejects',
   onClick: () => setShowOverrideDialog(true),
   hasCustomAction: true,
   disabled: !selectedCases || selectedCases.length < 1 || !clsPermissions?.update,
  },
  {
   label: 'Attach to Billing',
   onClick: () => setShowAttachToBillingDialog(true),
   hasCustomAction: true,
   disabled: !selectedCases || selectedCases.length < 1 || !clsPermissions?.update,
  },
 ];

 const isNotValid = !ignoreErrorsForm.formState.isValid;

 return (
  <Box component={Paper} borderRadius={0}>
   <Box display="flex" justifyContent="flex-end" padding={2}>
    {selectedCases.length > 0 && (
     <Typography mr={2} pt={1}>
      {selectedCases.length} case{selectedCases.length > 1 ? 's' : ''} selected
     </Typography>
    )}
    <SubMenuButton label="Actions" menu={subMenuActions} />
   </Box>
   <DialogConfirmation
    maxWidth="xs"
    open={showErrorCodeDialog}
    onClose={() => {
     ignoreErrorsForm.reset();
     setShowErrorCodeDialog(false);
    }}
    title="Ignore Errors"
    onConfirm={handleIgnoreErrorsBulkAction}
    disableConfirmLoader={isNotValid}
    isError={isError || isNotValid}
    sx={{
     '.MuiDialogContent-root': {
      paddingTop: 0,
     },
    }}
   >
    <Typography variant="subtitle1" pb={2}>
     {getPopupMessage(
      'Ignore Errors',
      selectedCases.filter((c) => !selectedCasesCantIgnore.find((sc) => sc.uuid === c)),
      selectedCasesCantIgnore,
      'RBP status must NOT be CANCELLED',
     )}
    </Typography>
    <DynamicForm isEditMode hookForm={ignoreErrorsForm} fields={ignoreErrorsFields} />
   </DialogConfirmation>
   <DialogConfirmation
    maxWidth="xs"
    open={showOverrideDialog}
    onClose={() => {
     overrideForm.reset();
     setShowOverrideDialog(false);
    }}
    title="Override Rejects"
    onConfirm={handleOverrideCasesRejectsBulkAction}
    disableConfirmLoader={isNotValid}
    isError={isError || isNotValid}
    sx={{
     '.MuiDialogContent-root': {
      paddingTop: 0,
     },
    }}
   >
    <Typography variant="subtitle1" pb={2}>
     {getPopupMessage(
      'Override Rejects',
      selectedCases.filter((c) => !selectedCasesCantOverride.find((sc) => sc.uuid === c)),
      selectedCasesCantOverride,
      'RBP status must be CLIENT RULE NOK',
     )}
    </Typography>
    <DynamicForm isEditMode hookForm={overrideForm} fields={claimOverrideFields} />
   </DialogConfirmation>
   <DialogConfirmation
    maxWidth="xs"
    open={showAttachToBillingDialog}
    onClose={() => {
     attachToBillingForm.reset();
     setShowAttachToBillingDialog(false);
    }}
    title="Attach to Billing"
    onConfirm={handleAttachToBillingBulkAction}
    disableConfirmLoader={isNotValid}
    isError={isError || isNotValid}
    sx={{
     '.MuiDialogContent-root': {
      paddingTop: 0,
     },
    }}
   >
    <Typography variant="subtitle1" pb={2}>
     {getPopupMessage(
      'Attach to Billing',
      selectedCases.filter((c) => !selectedCasesCantAttachToBilling.find((sc) => sc.uuid === c)),
      selectedCasesCantAttachToBilling,
      'RBP status must be FINANCIAL OK',
     )}
    </Typography>
    <DynamicForm isEditMode hookForm={attachToBillingForm} fields={attachToBillingFields} />
   </DialogConfirmation>
  </Box>
 );
}
