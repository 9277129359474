import { DynamicValue } from 'components/Dynamic/DynamicForm';
import { DynamicQueryData, DynamicQueryFieldType, DynamicSearchType } from './types';
import { QueryBuilder as Qbuilder } from 'lib/querybuilder';
import { convertDateToUTCIsoString } from 'utils/convertDate';

/**
 * generates spring filter query string from form field definitions and their associated values
 */

const isDateRangeFieldName = (
  fieldName: string | [string, string],
  searchType: DynamicSearchType,
): fieldName is [string, string] => searchType === 'dateRange' && Array.isArray(fieldName);

const isOnlyDateRangeFieldName = (
  fieldName: string | [string, string],
  searchType: DynamicSearchType,
): fieldName is [string, string] => searchType === 'onlyDateRange' && Array.isArray(fieldName);

export function getQueryString<T extends DynamicValue>(
  filterData: DynamicQueryData<T>,
  fieldDefinition: DynamicQueryFieldType<T>[],
) {
  const queryConfig = [];
  const operatorFields: { fieldName: string; operator: string }[] = [];

  for (const key in filterData) {
    const value = filterData[key];
    const fieldDef = fieldDefinition.find((item) => item.name === key);
    if (fieldDef && value) {
      /* Parsing added for composite field names. 'program/name' becomes 'program.name'.*/
      const parsedFieldName = fieldDef.searchFor || fieldDef.name.toString().replace('/', '.');

      const transformedValue = fieldDef.searchTransformer
        ? fieldDef.searchTransformer(value)
        : value;

      if (fieldDef.searchType === 'operator' && typeof parsedFieldName === 'string') {
        operatorFields.push({ fieldName: parsedFieldName, operator: transformedValue.toString() });
        continue;
      }

      if (fieldDef.operatorField) {
        const operatorDef = operatorFields.find(
          (item) => item.fieldName === fieldDef.operatorField,
        );
        if (operatorDef) {
          fieldDef.searchType = operatorDef.operator as DynamicSearchType;
        }
      }

      if (
        Array.isArray(fieldDef.searchFor) &&
        fieldDef.searchType !== 'dateRange' &&
        fieldDef.searchType !== 'onlyDateRange'
      ) {
        throw new Error(
          'searchFor array is only supported for dateRange and onlyDateRange searchType',
        );
      }

      if (isDateRangeFieldName(parsedFieldName, fieldDef.searchType)) {
        if (Array.isArray(transformedValue) && transformedValue.length > 0) {
          const [startDate, endDate] = transformedValue;
          const [searchRangeStart, searchRangeEnd = parsedFieldName[0]] = parsedFieldName;
          queryConfig.push(
            Qbuilder.greaterThanOrEqual(
              searchRangeStart,
              convertDateToUTCIsoString(new Date(startDate.toString())),
            ),

            Qbuilder.lessThanOrEqual(
              searchRangeEnd,
              convertDateToUTCIsoString(new Date(endDate.toString())),
            ),
          );
        }
        continue;
      }

      if (isOnlyDateRangeFieldName(parsedFieldName, fieldDef.searchType)) {
        if (Array.isArray(transformedValue) && transformedValue.length > 0) {
          const [startDate, endDate] = transformedValue;
          const [searchRangeStart, searchRangeEnd = parsedFieldName[0]] = parsedFieldName;
          queryConfig.push(
            Qbuilder.greaterThanOrEqual(searchRangeStart, startDate.toString()),

            Qbuilder.lessThanOrEqual(searchRangeEnd, endDate.toString()),
          );
        }
        continue;
      }

      switch (fieldDef.searchType) {
        case 'equal':
          queryConfig.push(Qbuilder.equal(parsedFieldName, transformedValue.toString()));
          break;
        case 'exists-equal':
          queryConfig.push(
            Qbuilder.exists(Qbuilder.equal(parsedFieldName, transformedValue.toString())),
          );
          break;
        case 'like':
          queryConfig.push(Qbuilder.like(parsedFieldName, `%${transformedValue.toString()}%`));
          break;
        case 'date': {
          queryConfig.push(
            Qbuilder.greaterThanOrEqual(
              parsedFieldName,
              convertDateToUTCIsoString(new Date(transformedValue.toString())),
            ),
          );
          queryConfig.push(
            Qbuilder.lessThanOrEqual(
              parsedFieldName,
              convertDateToUTCIsoString(new Date(transformedValue.toString()), true),
            ),
          );
          break;
        }
        case 'dateRange': {
          const [startDate, endDate] = transformedValue as [string, string];
          queryConfig.push(
            Qbuilder.greaterThanOrEqual(
              parsedFieldName,
              convertDateToUTCIsoString(new Date(startDate)),
            ),
          );
          queryConfig.push(
            Qbuilder.lessThanOrEqual(
              parsedFieldName,
              convertDateToUTCIsoString(new Date(endDate), true),
            ),
          );
          break;
        }
        case 'dateGreaterThan': {
          queryConfig.push(
            Qbuilder.greaterThanOrEqual(
              parsedFieldName,
              convertDateToUTCIsoString(new Date(transformedValue.toString()), true),
            ),
          );
          break;
        }
        case 'dateLessThan': {
          queryConfig.push(
            Qbuilder.lessThanOrEqual(
              parsedFieldName,
              convertDateToUTCIsoString(new Date(transformedValue.toString())),
            ),
          );
          break;
        }

        case 'in': {
          if (Array.isArray(transformedValue) && transformedValue.length > 0) {
            queryConfig.push(Qbuilder.in(parsedFieldName, ...transformedValue));
          }
        }
      }
    }
  }

  if (queryConfig.length < 1) {
    return '';
  }

  return Qbuilder.and(...queryConfig)?.toString() || '';
}
