import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { CaseViewHeader } from './CaseDetails/Header';
import {
  CreditCard,
  EventDetails,
  GeneralInfo,
  BreakdownAndTow,
  ErrorsAndComments,
  CaseFinancials,
} from './CaseDetails';
import { useEffect, useMemo, useState } from 'react';
import { ServicesProvided } from './CaseDetails/ServicesProvided/';
import { CaseDetailsContext } from './contexts/CaseDetails';
import { ExpandAllButton } from 'components/ExpandAllButton';
import { useCaseContext } from './CaseProvider';
import { CRUDComponentProps } from 'components/CRUDNavigator';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';

interface Props {
  previousTitle?: string;
  previousRoute?: string;
}

const CasePageView = ({ navigators, previousTitle, previousRoute }: Props & CRUDComponentProps) => {
  const [allSectionsExpanded, setAllSectionsExpanded] = useState(false);
  const [isEditingSection, setIsEditingSection] = useState(false);
  const { permissions: caseFinancialSession } =
    useRapCoreFeaturePermissions('caseFinancialSession');

  const { uuid } = useParams();
  const {
    requestById,
    getById: { isDone, data },
    navigation: { nextId, previousId },
    sortOptions,
  } = useCaseContext();

  useEffect(() => {
    requestById(uuid);
  }, [uuid, requestById]);

  const toggleAllSections = () => {
    setAllSectionsExpanded(!allSectionsExpanded);
  };

  const handleNext = nextId
    ? () => {
        nextId &&
          navigators.toIndividualScreen(nextId, {
            orderBy: sortOptions.orderBy || '',
            order: sortOptions.order || '',
          });
      }
    : undefined;

  const handlePrevious = previousId
    ? () => {
        previousId &&
          navigators.toIndividualScreen(previousId, {
            orderBy: sortOptions.orderBy || '',
            order: sortOptions.order || '',
          });
      }
    : undefined;

  const editOptions = useMemo(() => {
    const options = {
      disabled: true,
      tooltip: '',
    };
    if (isDone && data) {
      if (data.isEditable) {
        options.disabled = false;
      } else {
        options.tooltip = data.message;
      }
    }
    return options;
  }, [data, isDone]);

  return (
    <CaseDetailsContext.Provider
      value={{ allSectionsExpanded, isEditingSection, setIsEditingSection }}
    >
      <Stack spacing={2}>
        {data && (
          <>
            <CaseViewHeader
              caseData={data}
              onNext={handleNext}
              onPrevious={handlePrevious}
              previousTitle={previousTitle}
              previousRoute={previousRoute}
            />
            <ExpandAllButton expanded={allSectionsExpanded} toggleExpanded={toggleAllSections} />
            <GeneralInfo caseUUID={data.uuid} editOptions={editOptions} />
            <ErrorsAndComments caseUUID={data.uuid} rbpCaseStatus={data.rbpCaseStatus} />
            {caseFinancialSession.read && (
              <CaseFinancials
                caseUUID={data.uuid}
                clientId={data.clientId}
                rapCaseId={data.rapCaseId}
                rbpCaseStatus={data.rbpCaseStatus}
              />
            )}
            <CreditCard caseUUID={data.uuid} />
            <BreakdownAndTow caseUUID={data.uuid} editOptions={editOptions} />
            <ServicesProvided caseUUID={data.uuid} editOptions={editOptions} />
            <EventDetails caseUUID={data.uuid} editOptions={editOptions} />
          </>
        )}
      </Stack>
    </CaseDetailsContext.Provider>
  );
};

export default CasePageView;
