export enum AuditLogsActivity {
  CASE = 'CASE',
  BILLING = 'BILLING',
  RAP_CORE = 'RAP_CORE',
}

export enum AuditLogsIdType {
  CASE = 'CASE',
  BILL_REQUEST = 'BILL_REQUEST',
  BILL_CONFIG = 'BILL_CONFIG',
  PAYMENT_FORECAST = 'PAYMENT_FORECAST',
  PAYMENT_REQUEST = 'PAYMENT_REQUEST',
  CLIENT = 'CLIENT',
  PROGRAM = 'PROGRAM',
  SERVICE = 'SERVICE',
}

export enum AuditEntity {
  CLIENT = 'client',
  PROGRAM = 'program',
  SERVICE = 'service',
  CASE = 'case',
  BILL_REQUEST = 'billing-request',
  BILL_CONFIG = 'billing-config',
  PAYMENT_FORECAST = 'payment-forecast',
  PAYMENT_REQUEST = 'payment-request',
}

interface BaseDto {
  uuid: string;
  createdAtDateTime?: string;
  updatedAtDateTime?: string;
  createdByUserId?: string;
  updatedByUserId?: string;
}

export interface AuditLogsDto extends BaseDto {
  eventDateTime: string;
  eventRealm: AuditLogsActivity;
  entityType: AuditLogsIdType;
  entityUuid: string;
  entityId: string;
  eventOperation: string;
  operationUserId: string;
  operationUserEmail: string;
  action: string;
  collapsibleData?: Record<string, string>[];
}

export type AuditLogsData = AuditLogsDto;

export type AuditLogsSearch = {
  eventRealm?: AuditLogsActivity | string;
  entityType?: AuditLogsIdType | string;
  entityId?: string;
  dateFilter?: string;
  eventDateTime?: string;
  operationUserId?: string;
  operationUserEmail?: string;
};

export const AuditLogsActivityDescription: Record<AuditLogsActivity, string> = {
  CASE: 'CASE',
  BILLING: 'BILLING',
  RAP_CORE: 'RAP CORE',
};

export const AuditLogsIdTypeDescription: Record<AuditLogsIdType, string> = {
  CASE: 'Case',
  BILL_REQUEST: 'Billing Request',
  BILL_CONFIG: 'Billing Configuration',
  PAYMENT_FORECAST: 'Payment Forecast',
  PAYMENT_REQUEST: 'Payment Request',
  CLIENT: 'Client',
  PROGRAM: 'Program',
  SERVICE: 'Service',
};

export const auditLogsRelation: Record<AuditLogsActivity, AuditLogsIdType[]> = {
  [AuditLogsActivity.CASE]: [AuditLogsIdType.CASE],
  [AuditLogsActivity.BILLING]: [
    AuditLogsIdType.BILL_REQUEST,
    AuditLogsIdType.BILL_CONFIG,
    AuditLogsIdType.PAYMENT_FORECAST,
    AuditLogsIdType.PAYMENT_REQUEST,
  ],
  [AuditLogsActivity.RAP_CORE]: [
    AuditLogsIdType.CLIENT,
    AuditLogsIdType.PROGRAM,
    AuditLogsIdType.SERVICE,
  ],
};
