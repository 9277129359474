import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';
import { CaseData } from './interfaces/CaseData';
import { ResolutionTypes } from 'api/cases/interfaces/CaseErrorDto';

const editableStatuses: RbpCaseStatus[] = [
 RbpCaseStatus.RULE_NOK,
 RbpCaseStatus.ENRICH_NOK,
 RbpCaseStatus.CLIENT_RULE_NOK,
 RbpCaseStatus.FINANCIAL_NOK,
 RbpCaseStatus.FINANCIAL_OK,
];

const editableClientIds: string[] = [
 '35', // HCI
 '39', // AHM
];

export const isEditable = (rbpCaseStatus: RbpCaseStatus): boolean => {
 return editableStatuses.includes(rbpCaseStatus);
};

export const canRefreshGeneralInfoSection = (
 rbpCaseStatus: RbpCaseStatus,
 clientId: string,
): boolean => {
 return isEditable(rbpCaseStatus) && editableClientIds.includes(clientId);
};

export enum CaseBulkActionsEnum {
 CANCEL = 'CANCEL',
 CLOSE = 'CLOSE',
 IGNORE_ERROR = 'IGNORE_ERROR',
 UN_CANCEL = 'UN_CANCEL',
 CLAIM_OVERRIDE = 'CLAIM_OVERRIDE',
 ATTACH_TO_BILLING = 'ATTACH_TO_BILLING',
}

export function getCasesCantOverride(cases: CaseData[]): CaseData[] {
 return cases.filter((c) => c.rbpCaseStatus !== RbpCaseStatus.CLIENT_RULE_NOK);
}

export function getCasesCantAttachToBilling(cases: CaseData[]): CaseData[] {
 return cases.filter((c) => c.rbpCaseStatus !== RbpCaseStatus.FINANCIAL_OK);
}

export function getCasesCantClose(cases: CaseData[]): CaseData[] {
 return cases.filter(
  (c) =>
   c.callStatus === 'CLS' ||
   (c.rbpCaseStatus !== RbpCaseStatus.ENRICHED && c.rbpCaseStatus !== null),
 );
}

export function getCasesCantCancel(cases: CaseData[]): CaseData[] {
 return cases.filter(
  (c) =>
   c.rbpCaseStatus === RbpCaseStatus.BILLING_OK || c.rbpCaseStatus === RbpCaseStatus.CANCELLED,
 );
}

export function getCasesCantUnCancel(cases: CaseData[]): CaseData[] {
 return cases.filter((c) => c.rbpCaseStatus !== RbpCaseStatus.CANCELLED);
}

export function getCasesCantIgnore(cases: CaseData[]): CaseData[] {
 return cases.filter((c) => c.rbpCaseStatus === RbpCaseStatus.CANCELLED);
}

export function getPopupMessage(
 action: string,
 casesToRun: string[],
 casesToNotRun: CaseData[],
 rule: string,
): string {
 if (casesToRun.length === 0) {
  return `Cannot ${action} the selected cases. ${rule}.`;
 }
 return `Are you sure you want to ${action} ${casesToRun.length} case${
  casesToRun.length > 1 ? 's' : ''
 }?
    ${
     casesToNotRun.length
      ? `*Cases ${casesToNotRun.map((c) => c.rapCaseId).join(', ')}. ${rule}.`
      : ''
    }`;
}

/**
 * Builds an array of error codes based on the selected cases and their data.
 *
 * @param selectedCases - An array of case UUIDs representing the selected cases.
 * @param data - An array of CaseData objects containing the case data.
 * @returns An array of error codes with label and value properties.
 */
export const buildErrorCodesArray = (
 selectedCases: string[],
 data: CaseData[],
): { label: string; value: string }[] => {
 // Filter the data to include only the selected cases
 const selectedCaseData = data.filter((caseData) => selectedCases.includes(caseData.uuid));

 // Object to count occurrences of each error code across all selected cases
 const errorCodeCount: Record<string, number> = {};

 selectedCaseData.forEach((caseData) => {
  // Temporary set to track error codes in this specific case
  const caseErrorCodes = new Set<string>();

  caseData.caseErrors.forEach((caseError) => {
   // If a caseError is "ignorable" === true and resolutionType === "PENDING", then consider it
   if (caseError.ignorable && caseError.resolutionType === ResolutionTypes.PENDING) {
    caseErrorCodes.add(caseError.code);
   }
  });

  // Increment the count for each error code found in this case
  caseErrorCodes.forEach((code) => {
   errorCodeCount[code] = (errorCodeCount[code] || 0) + 1;
  });
 });

 // Filter error codes that are present in all selected cases
 const totalSelectedCases = selectedCaseData.length;
 const commonErrorCodes = Object.entries(errorCodeCount)
  .filter(([_, count]) => count === totalSelectedCases)
  .map(([code]) => ({ label: code, value: code }));

 return commonErrorCodes;
};
