import DataTable from 'components/DataTable';
import Stack from '@mui/material/Stack';
import { UseFormReturn, UseFormSetValue, useForm, useWatch } from 'react-hook-form';

import { CRUDComponent } from 'components/CRUDNavigator';
import { CaseData } from './interfaces/CaseData';
import {
  CaseDataTableConfig,
  CaseSearch,
  CaseSearchFields,
  CaseSearchInitialValues,
  useBulkActionsPermissions,
} from './CasePageConfig';
import { DynamicFieldType, DynamicSearchBox, getQueryString } from 'components/Dynamic';
import { EmptySearchCard, ErrorSearchCard } from 'components/Cards';
import { LoaderCard } from 'components/Cards/LoaderCard';
import {
  DynamicQueryData,
  DynamicQueryFieldType,
  DynamicSearchType,
} from 'components/Dynamic/DynamicSearchBox/types';
import { SavedSearchesBar } from './SavedSearchesBar';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCaseContext } from './CaseProvider';
import { CASE_ERROR_STATUSES } from './CaseDetails/ErrorsAndComments/config';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { parseQueryString } from 'lib/querybuilder/parser';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';
import AppColors from 'AppColors';
import { Box } from '@mui/material';
import {
  CaseSearchType,
  CaseSearchTypeDetails,
  CaseSearchTypeSelector,
} from './CaseSearchTypeSelector';
import { setRequestSearchByErrorCase } from './queries/manageCaseQueries';
import { CasePageListBulkActions } from './CasePageListBulkActions';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import { BulkActionStatus } from 'core/enums/BulkActionStatus';
import { useLocation } from 'react-router-dom';
import { useServicesProvidedList } from './CaseDetails/ServicesProvided/ServicesProvidedQuery';
import { useQueryClient } from 'react-query';

type BulkActionTracking = {
  uuid: string[];
};

function getCaseParams(): Record<string, string> {
  const filters = new URLSearchParams(location.search).get('filters') ?? '';
  const orderBy = new URLSearchParams(location.search).get('orderBy') ?? '';
  const order = new URLSearchParams(location.search).get('order') ?? '';
  const showErrors = CASE_ERROR_STATUSES.some((status) => filters?.includes(status));
  return showErrors ? { openDefaultErrorPanel: 'active', order, orderBy } : { order, orderBy };
}

function getDateFilter(
  dateFilter: string,
  previousDateFilter: MutableRefObject<string | undefined>,
  hookForm: UseFormReturn<CaseSearch>,
  filterFieldName: 'rapCallCreatedDateFilter' | 'rapCallClosedDateFilter',
  dateFieldName: 'rapCallCreatedDateTime' | 'rapCallClosedDateTime',
) {
  if (!dateFilter) {
    hookForm.setValue(filterFieldName, 'date');
  }

  if (previousDateFilter.current !== dateFilter) {
    hookForm.setValue(dateFieldName, undefined);
    previousDateFilter.current = dateFilter;
  }

  return {
    type: dateFilter === 'dateRange' ? DynamicFieldType.RANGE_DATE : DynamicFieldType.DATE,
    searchType: dateFilter === 'dateRange' ? (dateFilter as DynamicSearchType) : 'date',
  };
}

// TBD: Consider refactoring this component to use CRUDPageableList;
// needs to integrate with the SavedSearchesBar component.
const CasePageList: CRUDComponent = ({ navigators }) => {
  const {
    getAll: { isRunning, isDone, isEmpty, data, isError },
    paginationOptions,
    sortOptions,
    requestAll,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    filters,
    setFilters,
    clearQueryCache,
  } = useCaseContext();

  const queryClient = useQueryClient();

  const [selectedCases, setSelectedCases] = useState<string[]>([]);
  const [hasAppliedRules, setAppliedRules] = useState<boolean>(false);
  const [caseSearchType, setCaseSearchType] = useState<CaseSearchType>(
    filters?.includes(CaseSearchTypeDetails[CaseSearchType.CallKey].filterField)
      ? CaseSearchType.CallKey
      : CaseSearchType.CaseId,
  );
  const [notifications, setNotifications] = useState<NotificationType>();
  const [showBulkActionStatus, setShowBulkActionStatus] = useState<boolean>(false);
  const [bulkActionQueryTimerStarted, setBulkActionQueryTimerStarted] = useState<boolean>(false);

  const bulkActionQueryTimer = useRef<number | undefined>(undefined);

  const { data: servicesProvided } = useServicesProvidedList(true);

  // Used to identify if the user is a CLUB
  const { permissions: clubUser } = useRapCoreFeaturePermissions('clubUser');

  const { permissions: bulkActionsPermissions } = useBulkActionsPermissions();

  const { state } = useLocation();
  const internalState = state as { fromBulkActions?: boolean };

  const CaseFields = CaseSearchFields(caseSearchType, false, servicesProvided);

  const defaultValues = useMemo(() => {
    if (filters && filters !== '') {
      return parseQueryString(filters, CaseFields);
    }
    return CaseSearchInitialValues;
  }, [CaseFields, filters]);

  const hookForm = useForm({ defaultValues });

  useEffect(() => {
    const applyFilters = Boolean(localStorage.getItem('applyFilters'));

    if (!applyFilters && clubUser?.read && filters === '') {
      onReset();
    } else {
      localStorage.removeItem('applyFilters');
    }

    if (internalState && internalState.fromBulkActions) {
      setShowBulkActionStatus(true);
    }

    return () => {
      clearQueryCache();
      clearIntervals();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!order) {
      setOrder('desc');
    }

    if (!orderBy) {
      setOrderBy('rapCallCreatedDateTime');
    }
  }, [order, orderBy, setOrder, setOrderBy]);

  useEffect(() => {
    if (!filters || filters === '') {
      hookForm.reset(CaseSearchInitialValues);
    }
  }, [filters, hookForm]);

  const rapCallCreatedDateFilter = useWatch({
    control: hookForm.control,
    name: 'rapCallCreatedDateFilter',
  });
  const previousRapCallCreatedDateFilter = useRef<string | undefined>(rapCallCreatedDateFilter);
  const rapCallClosedDateFilter = useWatch({
    control: hookForm.control,
    name: 'rapCallClosedDateFilter',
  });
  const previousRapCallClosedDateFilter = useRef<string | undefined>(rapCallClosedDateFilter);

  const searchFields = useMemo(() => {
    return CaseFields.map((field) => {
      if (field.name === 'rapCallCreatedDateTime') {
        const dateFilter = getDateFilter(
          rapCallCreatedDateFilter,
          previousRapCallCreatedDateFilter,
          hookForm,
          'rapCallCreatedDateFilter',
          'rapCallCreatedDateTime',
        );

        return {
          ...field,
          ...dateFilter,
        };
      }

      if (field.name === 'rapCallClosedDateTime') {
        const dateFilter = getDateFilter(
          rapCallClosedDateFilter,
          previousRapCallClosedDateFilter,
          hookForm,
          'rapCallClosedDateFilter',
          'rapCallClosedDateTime',
        );
        return {
          ...field,
          ...dateFilter,
        };
      }

      return field;
    });
  }, [rapCallCreatedDateFilter, rapCallClosedDateFilter, CaseFields, hookForm]);

  const handleCustomQuery = useCallback(
    (
      value: string,
      setValue?: UseFormSetValue<CaseSearch>,
      data?: DynamicQueryData<CaseSearch>,
    ) => {
      let customQuery = value;

      // Check if the query value includes a specific error code.
      if (value?.includes('errors.code') && data && !hasAppliedRules) {
        setAppliedRules(true);
        // Force the error code status to pending.
        setValue?.('errorCodeStatus', 'PENDING');
        data.errorCodeStatus = 'PENDING';

        // Transform the data object into a query string.
        customQuery = getQueryString(data, CaseFields);
      }

      if (data && data.errorCode && data.errorCodeStatus) {
        return setRequestSearchByErrorCase(data, CaseFields, clubUser?.read);
      }

      // If the user is CLUB, add errors.responsible param
      if (value?.includes('errors') && clubUser?.read) {
        // Regular expression to find the pattern
        const regex = /AND exists\(errors\.resolutionType : '(\w+)'(.*?)\)/;

        // Replacement string
        const replacement =
          "AND exists(errors.resolutionType : '$1' and errors.responsible : 'CLUB')";

        // Perform the replacement
        const newString = customQuery.replace(regex, replacement);
        customQuery = newString;
      }

      return customQuery;
    },
    [hasAppliedRules, clubUser?.read, CaseFields],
  );

  const clearQueryParams = () => {
    window.history.pushState({}, document.title, window.location.pathname);
  };

  const clearIntervals = useCallback(() => {
    clearInterval(bulkActionQueryTimer.current);
    bulkActionQueryTimer.current = undefined;
    setBulkActionQueryTimerStarted(false);
  }, [bulkActionQueryTimer]);

  useEffect(() => {
    if (bulkActionQueryTimer.current !== undefined && data) {
      const bulkActionIsPending =
        data.findIndex((item) => item.bulkActionStatus === BulkActionStatus.PENDING) !== -1;
      if (!bulkActionIsPending && bulkActionQueryTimerStarted) {
        clearIntervals();
      }
    }
  }, [data, clearIntervals, bulkActionQueryTimerStarted]);

  const onRowClick = useCallback(
    (caseData: CaseData, showActions: boolean) => {
      clearIntervals();
      const queryParams = getCaseParams();
      navigators.toIndividualScreen(caseData.uuid, queryParams, { fromBulkActions: showActions });
    },
    [clearIntervals, navigators],
  );

  const onReset = () => {
    clearQueryParams();
    setFilters('');
    setAppliedRules(false);
    hookForm.reset(CaseSearchInitialValues);

    setShowBulkActionStatus(false);
    clearIntervals();
  };

  const onSubmit = (filters: string) => {
    queryClient.invalidateQueries(['QUERY_CASES_KEY_ALL']);
    requestAll(filters);
  };

  const onBulkActionSubmit = (bulkActionCaseIds: string[]) => {
    setShowBulkActionStatus(true);
    queryBulkActionCases(bulkActionCaseIds);
    if (bulkActionQueryTimer.current == undefined) {
      const queryTimer = setInterval(() => {
        setBulkActionQueryTimerStarted(true);
        queryBulkActionCases(bulkActionCaseIds);
      }, 10000);
      bulkActionQueryTimer.current = queryTimer as unknown as number;
    }
  };

  const queryBulkActionCases = (bulkActionCaseIds: string[]) => {
    const filterData: DynamicQueryData<BulkActionTracking> = { uuid: bulkActionCaseIds };
    const fieldDefinition: DynamicQueryFieldType<BulkActionTracking>[] = [
      {
        searchType: 'in',
        label: 'uuid',
        name: 'uuid',
      },
    ];
    const queryString = getQueryString(filterData, fieldDefinition);
    onSubmit(queryString);
  };

  return (
    <Stack spacing="10px" flexGrow={1}>
      <DynamicSearchBox
        data-testid="CaseSearch"
        useFormReturn={hookForm}
        name="CaseSearch"
        fields={searchFields}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onReset={onReset}
        colspan={2.4}
        header={(useFormReturn, submit) => (
          <Box sx={{ display: 'flex' }}>
            <CaseSearchTypeSelector
              value={caseSearchType}
              onChange={(value: CaseSearchType) => setCaseSearchType(value)}
            />
            <SavedSearchesBar
              caseSearchType={caseSearchType}
              currentSearchData={filters ? useFormReturn.getValues() : undefined}
              onSelectedSavedSearchChange={(searchData) => {
                if (searchData) {
                  useFormReturn.reset();
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  Object.keys(searchData).forEach((key: any) => {
                    useFormReturn.setValue(key, searchData[key]);
                  });
                  submit();
                }
              }}
            />
          </Box>
        )}
        handleCustomQuery={handleCustomQuery}
      />
      <Stack flexGrow={1}>
        {isRunning && <LoaderCard label="Loading Cases List..." sx={{ flexGrow: 1 }} />}

        {isEmpty && !data.length && <EmptySearchCard sx={{ flexGrow: 1 }} />}

        {isError && <ErrorSearchCard sx={{ flexGrow: 1 }} />}

        {isDone && data.length > 0 && (
          <>
            {bulkActionsPermissions?.read && (
              <CasePageListBulkActions
                selectedCases={selectedCases}
                setSelectedCases={setSelectedCases}
                setNotifications={setNotifications}
                data={data || []}
                onBulkActionSubmit={onBulkActionSubmit}
              />
            )}

            <DataTable<CaseData>
              hasSelectors={bulkActionsPermissions?.read}
              onSelect={setSelectedCases}
              data-testid="CaseList"
              indexName={'uuid'}
              onRowClick={(row) => onRowClick(row, showBulkActionStatus)}
              columns={CaseDataTableConfig(showBulkActionStatus)}
              rows={data || []}
              paginationOptions={paginationOptions}
              sortOptions={{ ...sortOptions }}
              stickyHeader
              rowSx={(row) =>
                row.rbpCaseStatus === RbpCaseStatus.CANCELLED
                  ? {
                      color: AppColors.AAA_DUSTY_GREY,
                    }
                  : null
              }
            />
          </>
        )}
      </Stack>
      <NotificationSnackbar
        clearNotification={() => setNotifications(undefined)}
        notification={notifications}
      />
    </Stack>
  );
};

export default CasePageList;
