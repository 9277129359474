import { useGetReportsByBillingRequestNumber } from 'features/Reports/ReportsQuery';
import { BillingRequestData } from '../types';
import {
  Box,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Button,
} from '@mui/material';
import AppColors from 'AppColors';
import { LoaderCard } from 'components/Cards';
import { ReactComponent as SearchEmpty } from 'assets/search-empty.svg';
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn';
import DownloadIcon from '@mui/icons-material/Download';
import { usePaymentForecastsDownloadOneReports } from 'features/PaymentForecasts/PaymentForecastsQuery';
import { reportsColumns } from '../helpers/attachedReportsConfig';
import { ReportData } from 'features/Reports/types';
import DataTable from 'components/DataTable';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import { useState } from 'react';
import { downloadReportFile } from 'features/Reports/utils';

interface AttachedCasesTableProps {
  billingRequestData: BillingRequestData;
}

export const AttachedReportsTable = ({ billingRequestData }: AttachedCasesTableProps) => {
  const [notifications, setNotifications] = useState<NotificationType>();

  const { data, isLoading, isDone, isEmpty, isError } = useGetReportsByBillingRequestNumber(
    billingRequestData.number,
    {
      page: 0,
      size: 25,
    },
  );

  const { mutateAsync: downloadReport, isLoading: isLoadingDownloadReport } =
    usePaymentForecastsDownloadOneReports();

  const downloadFile = (res: any, reportName: string, fileName: string) => {
    downloadReportFile({
      res,
      reportName,
      setNotifications,
      fileType: 'application/pdf',
      fileName,
    });
  };

  const downloadAllReports = () => {
    if (!data?.content) return;

    Promise.all(data.content.map((report) => downloadReport(report.reportUuid)))
      .then((res) => {
        res.forEach((r, i) =>
          downloadFile(
            r,
            data.content[i].reportName,
            data.content[i].s3Path.split('/').pop() ?? '',
          ),
        );
      })
      .catch(() => {
        setNotifications({
          name: 'Download all reports',
          type: 'error',
          message: 'The reports have not been downloaded, try again later',
        });
      });
  };

  const actions = (row: ReportData) => {
    return isLoadingDownloadReport ? (
      <Stack spacing={1}>
        <CircularProgress size={24} />
      </Stack>
    ) : (
      <Stack
        spacing={1}
        direction={'row'}
        sx={{
          minHeight: '24px',
        }}
      >
        <Tooltip title="Download Report" placement="top">
          <IconButton
            sx={{
              padding: 0,
              top: '2px',
              cursor: 'pointer',
              color: 'primary.dark',
              '&:hover': { color: 'primary.main' },
            }}
            onClick={(e) => {
              e.stopPropagation();
              downloadReport(row.reportUuid)
                .then((res: any) =>
                  downloadFile(res, row.reportName, row.s3Path.split('/').pop() ?? ''),
                )
                .catch(() => {
                  setNotifications({
                    name: `Download ${row.reportName} Report`,
                    type: 'error',
                    message: `The ${row.reportName} report has not been downloaded, try again later`,
                  });
                });
            }}
          >
            <DownloadIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  const columns = reportsColumns(actions);

  return (
    <Box mt={8}>
      <Box component={Paper}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box display="flex" alignItems="center" p={2} gap={1}>
            <AssignmentTurnedIn />
            <Typography variant="h6" sx={{ fontWeight: '700' }}>
              Reports
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" p={2} gap={1}>
            {isDone && data && (
              <Tooltip title="Download all reports" arrow>
                <div>
                  <Button variant="outlined" onClick={downloadAllReports}>
                    <SaveAltIcon />
                  </Button>
                </div>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Divider sx={{ borderColor: AppColors.AAA_LIGHT_GRAY, mb: 2 }} />
        <Box pb={3}>
          <NotificationSnackbar
            clearNotification={() => setNotifications(undefined)}
            notification={notifications}
          />
          {isLoading && <LoaderCard label="Loading reports..." sx={{ p: 4 }} />}
          {(isEmpty || isError) && <EmptyReportsCard />}
          {isDone && data && <DataTable<ReportData> columns={columns} rows={data.content} />}
        </Box>
      </Box>
    </Box>
  );
};

const EmptyReportsCard = () => (
  <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" my={8}>
    <SearchEmpty />
    <Typography variant="h5" sx={{ fontWeight: 500, pt: 3 }}>
      No report found.
    </Typography>
  </Box>
);
