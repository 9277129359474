import { DataTableColumn } from 'components/DataTable';
import { DynamicFieldType } from 'components/Dynamic';
import { DynamicQueryFieldType } from 'components/Dynamic/DynamicSearchBox/types';
import {
  AuditLogsActivity,
  AuditLogsActivityDescription,
  AuditLogsData,
  AuditLogsIdTypeDescription,
  auditLogsRelation,
  AuditLogsSearch,
} from './types';
import { convertUTCToDateTimeString } from 'utils/convertDate';

const DateFilterValues = [
  { label: 'After', value: 'dateGreaterThan' },
  { label: 'Before', value: 'dateLessThan' },
  { label: 'Equal to', value: 'date' },
  { label: 'In between', value: 'dateRange' },
];

export const AuditLogsSearchDefaultValues: AuditLogsSearch = {
  eventRealm: '',
  entityType: '',
  entityId: '',
  dateFilter: 'date',
  eventDateTime: '',
  operationUserId: '',
};

export const getAuditLogSearchFields = (
  selectedActivity: AuditLogsActivity,
  idTypeFilterSelected: boolean,
): DynamicQueryFieldType<AuditLogsSearch>[] => {
  const filteredIdTypeOptions = auditLogsRelation[selectedActivity]?.map((idType) => ({
    label: AuditLogsIdTypeDescription[idType],
    value: idType,
  }));

  return [
    {
      name: 'eventRealm',
      label: 'Activities',
      searchType: 'equal',
      type: DynamicFieldType.SELECT,
      select: {
        options: Object.entries(AuditLogsActivityDescription).map(([value, label]) => ({
          label,
          value,
        })),
      },
      colspan: 2,
    },
    {
      name: 'entityType',
      label: 'ID Type',
      searchType: 'equal',
      type: DynamicFieldType.SELECT,
      select: {
        options:
          filteredIdTypeOptions ||
          Object.entries(AuditLogsIdTypeDescription).map(([value, label]) => ({
            label,
            value,
          })),
      },
      colspan: 2,
    },
    {
      name: 'entityId',
      label: 'ID',
      searchType: 'equal',
      type: DynamicFieldType.TEXT,
      colspan: 2,
      isReadOnly: !idTypeFilterSelected,
    },
    {
      name: 'dateFilter',
      label: 'Date Filter',
      type: DynamicFieldType.SELECT,
      searchType: 'operator',
      select: {
        options: DateFilterValues.map((item) => ({ label: item.label, value: item.value })),
      },
      colspan: 1.5,
    },
    {
      name: 'eventDateTime',
      label: 'Call Date',
      searchType: 'date',
      operatorField: 'dateFilter',
      type: DynamicFieldType.DATE,
      colspan: 2.2,
    },
    {
      name: 'operationUserId',
      label: 'User',
      searchType: 'equal',
      type: DynamicFieldType.TEXT,
      colspan: 2.3,
    },
  ];
};

export const AuditLogsDataTableConfig: DataTableColumn<AuditLogsData>[] = [
  {
    id: 'eventDateTime',
    label: 'Date',
    sortable: true,
    accessor: (row) => convertUTCToDateTimeString(row.eventDateTime),
  },
  { id: 'action', label: 'Action Taken' },
  {
    id: 'eventRealm',
    label: 'Activity',
    sortable: true,
    accessor: (row) => AuditLogsActivityDescription[row.eventRealm],
  },
  {
    id: 'entityType',
    label: 'ID Type',
    sortable: true,
    accessor: (row) => AuditLogsIdTypeDescription[row.entityType],
  },
  { id: 'entityId', label: 'ID', sortable: true },
  {
    id: 'operationUserId',
    label: 'User',
    sortable: true,
    accessor: (row) => row.operationUserEmail || 'RBP process',
  },
];
