import { CRUDComponent } from 'components/CRUDNavigator';
import { CRUDPageableList } from 'components/CRUDPageable';
import {
  AuditLogsDataTableConfig,
  AuditLogsSearchDefaultValues,
  getAuditLogSearchFields,
} from './AuditLogsPageConfig';
import { useForm, useWatch } from 'react-hook-form';
import { AuditLogsActivity, AuditLogsIdType, auditLogsRelation, AuditLogsSearch } from './types';
import { useEffect, useMemo, useRef } from 'react';
import { DynamicFieldType } from 'components/Dynamic';
import { DynamicSearchType } from 'components/Dynamic/DynamicSearchBox/types';
import { useAuditLogsContext } from './AuditLogsProvider';

const AuditLogsPageList: CRUDComponent = ({ navigators }) => {
  const context = useAuditLogsContext();

  const hookForm = useForm<AuditLogsSearch>({ defaultValues: AuditLogsSearchDefaultValues });

  const dateFilter = useWatch({
    control: hookForm.control,
    name: 'dateFilter',
  });

  const activityFilter = useWatch({
    control: hookForm.control,
    name: 'eventRealm',
  });

  const idTypeFilter = useWatch({
    control: hookForm.control,
    name: 'entityType',
  });

  const entityIdFilter = useWatch({
    control: hookForm.control,
    name: 'entityId',
  });

  const searchFields = useMemo(() => {
    const auditLogsSearchFields = getAuditLogSearchFields(
      activityFilter as AuditLogsActivity,
      Boolean(idTypeFilter),
    );

    hookForm.setValue('eventDateTime', undefined);

    return auditLogsSearchFields?.map((field) => {
      if (field.name === 'eventDateTime') {
        return {
          ...field,
          type: dateFilter === 'dateRange' ? DynamicFieldType.RANGE_DATE : DynamicFieldType.DATE,
          searchType: dateFilter === 'dateRange' ? (dateFilter as DynamicSearchType) : 'date',
        };
      }
      return field;
    });
  }, [activityFilter, dateFilter, idTypeFilter, hookForm]);

  // Ref used to validate if the new filter is different from the
  const previousIdType = useRef<string | undefined>();

  useEffect(() => {
    if (idTypeFilter !== previousIdType.current) {
      previousIdType.current = idTypeFilter;

      if (idTypeFilter) {
        const newActivity = Object.entries(auditLogsRelation).find(([_, idTypes]) =>
          idTypes.includes(idTypeFilter as AuditLogsIdType),
        )?.[0];

        if (newActivity) {
          hookForm.setValue('eventRealm', newActivity as AuditLogsActivity);
        }
      }
    }
  }, [idTypeFilter, hookForm]);

  useEffect(() => {
    const condition = auditLogsRelation[activityFilter as AuditLogsActivity]?.includes(
      idTypeFilter as AuditLogsIdType,
    );

    if (!condition) {
      hookForm.setValue('entityType', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityFilter, hookForm]);

  useEffect(() => {
    if (context.orderBy && context.order) {
      return;
    }

    context.setOrderBy('eventDateTime');
    context.setOrder('desc');
  }, [context]);

  useEffect(() => {
    if (!activityFilter || !idTypeFilter || !entityIdFilter) {
      return;
    }

    if (
      activityFilter === AuditLogsActivity.CASE &&
      idTypeFilter === AuditLogsIdType.CASE &&
      entityIdFilter.length === 8
    ) {
      hookForm.setValue('entityId', entityIdFilter.replace(/^0+(?=\S)/, ''));
      return;
    }

    if (
      activityFilter === AuditLogsActivity.BILLING &&
      idTypeFilter === AuditLogsIdType.BILL_REQUEST &&
      entityIdFilter.length === 5
    ) {
      const prefix = entityIdFilter;
      hookForm.setValue('entityId', `${prefix}.000000`);
      return;
    }
  }, [entityIdFilter, hookForm, idTypeFilter, activityFilter]);

  const clearQueryParams = () => {
    window.history.pushState({}, document.title, window.location.pathname);
  };

  const onReset = () => {
    clearQueryParams();
  };

  return (
    <CRUDPageableList
      name="Audit Log"
      context={context}
      navigators={navigators}
      searchFields={searchFields}
      tableColumns={AuditLogsDataTableConfig}
      searchDefaultValues={AuditLogsSearchDefaultValues}
      canCreate={false}
      useFormReturn={hookForm}
      searchBoxTitle=""
      onReset={onReset}
      onRowClick={() => null}
      isCollapsible
    />
  );
};

export default AuditLogsPageList;
