import CaseRejectDetails from './CaseRejectDetails';
import BalanceSheet from './BalanceSheet';
import BillingStatus from './BillingStatus';
import { CaseFinancialData } from './types';
import CaseClaim from './CaseClaim';
import theme from './theme';
import { ThemeProvider } from '@emotion/react';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';

// GM client id's
const ELIGIBLE_CLIENT_IDS = [52, 53];

interface Props {
  caseFinancialsData: CaseFinancialData;
  caseUUID: string;
  clientId: number;
  rapCaseId: string;
  rbpCaseStatus: RbpCaseStatus;
}

export default function CaseFinancialsDetails({
  caseFinancialsData,
  caseUUID,
  clientId,
  rapCaseId,
  rbpCaseStatus,
}: Props) {
  return (
    <>
      <BillingStatus
        billingStatus={caseFinancialsData.billingRequest?.statusHistory || []}
        billingRequestNumber={caseFinancialsData.billingRequest?.number}
        paymentRequest={caseFinancialsData.paymentRequest}
      />
      <BalanceSheet data={caseFinancialsData} />
      {ELIGIBLE_CLIENT_IDS.find((id) => id === clientId) ? (
        <ThemeProvider theme={theme}>
          <CaseClaim caseUUID={caseUUID} />
        </ThemeProvider>
      ) : (
        <CaseRejectDetails
          caseUUID={caseUUID}
          rapCaseId={rapCaseId}
          clientId={clientId}
          rbpCaseStatus={rbpCaseStatus}
        />
      )}
    </>
  );
}
