export function convertUTCDateToString(date?: string, timezone?: string): string {
  if (date) {
    return new Date(date).toLocaleDateString('en-US', {
      timeZone: timezone ? timezone : 'UTC',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }
  return '';
}

export function convertDateToUTCIsoString(date: Date, end?: boolean): string {
  const time = end ? 'T23:59:59.999Z' : 'T00:00:00.000Z';
  return date.toISOString().slice(0, 10) + time;
}

export function convertUTCToDateTimeString(
  date?: string,
  hour12 = true,
  timezone?: string,
): string {
  if (date) {
    const dateObject = new Date(date);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: hour12,
      timeZone: timezone ? timezone : 'UTC',
      month: '2-digit',
      day: '2-digit',
    }).format(dateObject);
  }
  return '';
}

export const changeTime = (value: string | undefined, time: string) => {
  if (value !== undefined && time !== undefined) {
    const [datePart] = value.split('T');
    return `${datePart}${time}`;
  }
  return '';
};

export const convertToTime = (value?: string) => {
  if (value) {
    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'UTC',
    }).format(new Date(value));
  }
  return '';
};

export const combineDateAndTime = (dateString: string, timeString: string): string => {
  const [month, day, year] = dateString.split('/');
  const [time, meridiem] = timeString.split(' ');

  const [hourString, minuteString] = time.split(':');
  const hour = parseInt(hourString);
  const minute = parseInt(minuteString);

  let combinedDate: Date;
  if (meridiem.toUpperCase() === 'PM' && hour !== 12) {
    combinedDate = new Date(
      Date.UTC(Number(year), Number(month) - 1, Number(day), hour + 12, minute),
    );
  } else if (meridiem.toUpperCase() === 'AM' && hour === 12) {
    combinedDate = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), 0, minute));
  } else {
    combinedDate = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), hour, minute));
  }

  return combinedDate.toISOString();
};

export function convertUTCDateToDDMMYYYY(date: string): string {
  const dateObj = new Date(date);
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const year = dateObj.getFullYear();

  return `${day}-${month}-${year}`;
}

export function convertToMMYYYY(date: string): string {
  const dateObj = new Date(date);
  // Convert to UTC
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
  const year = dateObj.getUTCFullYear();

  return `${month}/${year}`;
}

export function convertDateToUTCFormat(dateString: string) {
  const date = new Date(dateString);

  // Adjusting to UTC
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  // Extracting the day, month, and year
  const day = String(utcDate.getDate()).padStart(2, '0');
  const month = String(utcDate.getMonth() + 1).padStart(2, '0'); // January is 0
  const year = utcDate.getFullYear();

  // Returning in DD-MM-YYYY format
  return `${day}-${month}-${year}`;
}
