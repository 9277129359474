/**
 * Extracts the city, state, and postal code from an address string.
 * @param {string | undefined} address - The address string to extract information from.
 * @returns {{ city: string; state: string; postalCode: string }} An object containing the city, state, and postal code.
 */
export function extractAddressParts(address: string | undefined): {
  city: string;
  state: string;
  postalCode: string;
} {
  if (!address) return { city: '', state: '', postalCode: '' };

  // Regex to handle city, optional state, and zip code
  const match = address.match(/^([\w\s-]+),\s([A-Za-z\s]+)\s([A-Za-z0-9]{5}|[A-Za-z0-9]{6})?$/);

  if (match) {
    return {
      city: match[1].trim() || '',
      state: match[2]?.trim() || '',
      postalCode: match[3].trim() || '',
    };
  }

  return { city: '', state: '', postalCode: '' };
}
