/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, MouseEvent, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import isEqual from 'lodash/isEqual';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import SaveSearchPopup from './SaveSearchPopup/SaveSearchPopup';
import SavedSearchesPopover from './SavedSearchesPopover/SavedSearchesPopover';
import Typography from '@mui/material/Typography';
import { BasicSavedSearch, SavedSearch } from 'api/savedSearches/interfaces/SavedSearch';
import { useSavedSearchesContext } from './SavedSearchesBarProvider';
import { SaveSearchButton, ShowSavedSearchesButton } from './styles';
import { CaseSearchType } from '../CaseSearchTypeSelector';
import { parseSelectedSavedSeach, trimObject } from './helpers';

export interface SavedSearchesBarContainerProps {
  caseSearchType: CaseSearchType;
  currentSearchData?: Record<string, any>;
  onSelectedSavedSearchChange: (searchData: Record<string, any> | null) => void;
}

const SavedSearchesBarContainer: React.FC<SavedSearchesBarContainerProps> = ({
  caseSearchType,
  onSelectedSavedSearchChange,
  currentSearchData,
}: SavedSearchesBarContainerProps) => {
  const navigate = useNavigate();
  const [anchorButton, setAnchorButton] = useState<HTMLButtonElement | null>(null);
  const [showSaveSearchPopup, setShowSaveSearchPopup] = useState<boolean>(false);

  const {
    requestAll,
    getAll: { data: savedSearches, isDone },
    queries: { useDeleteByUUID, useCreate },
  } = useSavedSearchesContext();

  const savedSearchesSorted = useMemo(
    () => savedSearches?.sort((a) => (a.defaultFilter ? -1 : 1)),
    [savedSearches],
  );

  const currentSearch = useMemo(
    () => (currentSearchData ? JSON.stringify(currentSearchData) : null),
    [currentSearchData],
  );

  const selectedSavedSearchId = useMemo(() => {
    const currentFilter = currentSearchData ? trimObject(currentSearchData) : null;
    return currentSearchData
      ? savedSearchesSorted.find((item) => {
          const searchFilter = parseSelectedSavedSeach(item, caseSearchType);
          const trimmedFilter = trimObject(searchFilter);
          const filterString = trimmedFilter ?? item.filter;
          return isEqual(filterString, currentFilter);
        })?.customFilterId
      : null;
  }, [savedSearchesSorted, currentSearchData, caseSearchType]);

  const selectedSavedSearch = useMemo(() => {
    if (!selectedSavedSearchId) {
      return null;
    }
    const savedSearch = savedSearchesSorted.find(
      (item) => item.customFilterId === selectedSavedSearchId,
    );
    return isDone ? savedSearch : undefined;
  }, [isDone, savedSearchesSorted, selectedSavedSearchId]);

  const deleteSavedSearch = useDeleteByUUID();
  const createSavedSearch = useCreate();

  const onSavedSearchSelect = useCallback(
    (savedSearch: SavedSearch) => {
      onSelectedSavedSearchChange(parseSelectedSavedSeach(savedSearch, caseSearchType));
    },
    [onSelectedSavedSearchChange, caseSearchType],
  );

  useEffect(() => {
    requestAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDone && selectedSavedSearch === undefined) {
      const defaultSearch = savedSearchesSorted.find((item) => item.defaultFilter);
      if (defaultSearch) {
        onSavedSearchSelect(defaultSearch);
      }
    }
  }, [isDone, selectedSavedSearch, savedSearchesSorted, onSavedSearchSelect]);

  const onSavedSearchEdit = (savedSearchId: string) => {
    navigate(`${routes.editQuery}/${savedSearchId}`);
  };

  const onSavedSearchesPopoverClose = () => {
    setAnchorButton(null);
  };

  const onSaveSearchPopupClose = () => {
    setShowSaveSearchPopup(false);
  };

  const onSavedSearchCreate = async (createdSearch: BasicSavedSearch) => {
    await createSavedSearch.mutateAsync({
      ...createdSearch,
      createdDate: '',
      customFilterId: '',
      createdByUserId: '',
    });
    onSaveSearchPopupClose();
    onSavedSearchesPopoverClose();
  };

  const onSavedSearcheDelete = async (deletedSearchId: string) => {
    await deleteSavedSearch.mutateAsync(deletedSearchId);
    onSavedSearchesPopoverClose();
    if (selectedSavedSearchId === deletedSearchId) {
      onSelectedSavedSearchChange(null);
    }
  };

  const onSaveSearchButtonClick = () => setShowSaveSearchPopup(true);

  const onShowSavedSearchesClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorButton(event.currentTarget);
  };

  return (
    <Box
      sx={{
        m: '0 20px 15px',
        lineHeight: 1,
        display: 'flex',
        justifyContent: 'space-between',
        flexGrow: 1,
      }}
    >
      {isDone && (
        <>
          <ShowSavedSearchesButton
            data-testid="ShowSavedQueriesButton"
            id="show-saved-queries-button"
            startIcon={anchorButton ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            onClick={onShowSavedSearchesClick}
          >
            <Typography variant="button">
              {selectedSavedSearch?.name || 'Default Search Query'}
            </Typography>
          </ShowSavedSearchesButton>
          <SavedSearchesPopover
            anchorButton={anchorButton}
            onSavedSearchEdit={onSavedSearchEdit}
            onSavedSearchSelect={onSavedSearchSelect}
            onSavedSearchDelete={onSavedSearcheDelete}
            onClose={onSavedSearchesPopoverClose}
            savedSearches={savedSearchesSorted}
          />
        </>
      )}

      {currentSearch && (
        <>
          <SaveSearchButton
            id="save-search-button"
            startIcon={<AddIcon />}
            onClick={onSaveSearchButtonClick}
          >
            save search
          </SaveSearchButton>
          <SaveSearchPopup
            open={showSaveSearchPopup}
            appliedFilter={currentSearch}
            onClose={onSaveSearchPopupClose}
            savedSearches={savedSearches}
            onSubmit={onSavedSearchCreate}
            isLoading={createSavedSearch.isLoading}
          />
        </>
      )}
    </Box>
  );
};

export default SavedSearchesBarContainer;
