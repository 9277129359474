import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  fetchBreakdownLocationByUUID,
  fetchCaseCreditCardByUUID,
  fetchTowDestinationsByUUID,
  fetchCaseErrorsByUUID,
  fetchCaseEventDetailsByUUID,
  fetchCaseCommentsByUUID,
  updateCaseEventDetails,
  addUserComment,
  updateCaseErrorByUUID,
  addCaseAdjustment,
  fetchCaseCreditCardReceiptByUUID,
  updateCaseStatus,
  fetchCaseSmsByUUID,
  fetchCaseAttachableBillingRequests,
  updateCallStatus,
  executeBulkAction,
} from 'api/cases/manageCasesApi';

import { EventDetailsDto } from 'api/cases/interfaces/EventDetailsDto';
import { CaseEventData, CaseEventDetails } from '../interfaces/CaseEventDetails';
import { BaseCommentDto } from 'api/cases/interfaces/CommentDto';
import orderBy from 'lodash.orderby';
import { CaseErrorDto } from 'api/cases/interfaces/CaseErrorDto';
import { convertToTime, convertUTCDateToString } from 'utils/convertDate';
import { useManagedMutation } from 'hooks/useReactQueryHelpers';
import { CreditCardTransactionDto } from 'api/creditCards/interfaces/CreditCardTransactionDto';
import { CreditCardData } from 'features/CreditCards/types';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';
import { BillingRequestDto } from 'api/invoices';
import { BulkActionDto } from 'api/cases/interfaces/BulkActionDto';

import { getQueryString } from 'components/Dynamic';
import { DynamicQueryData, DynamicQueryFieldType } from 'components/Dynamic/DynamicSearchBox/types';
import { CaseSearch } from '../CasePageConfig';

export const QUERY_CASES_BY_UUID = 'QUERY_CASES_KEY_BYID';
export const QUERY_MANAGE_CASE_CREDIT_CARD_BY_UUID = 'QUERY_MANAGE_CASE_CREDIT_CARD_BY_UUID';
export const QUERY_MANAGE_CASE_CREDIT_CARD_RECEIPT_BY_UUID =
  'QUERY_MANAGE_CASE_CREDIT_CARD_RECEIPT_BY_UUID';
const QUERY_BREAKDOWN_LOCATION_BY_UUID = 'QUERY_BREAKDOWN_LOCATION_BY_UUID';
const QUERY_TOW_DESTINATIONS_BY_UUID = 'QUERY_TOW_DESTINATIONS_BY_UUID';
export const QUERY_MANAGE_CASE_ERRORS_BY_UUID = 'QUERY_MANAGE_CASE_ERRORS_BY_UUID';
const QUERY_MANAGE_CASE_EVENT_DETAILS_BY_UUID = 'QUERY_MANAGE_CASE_EVENT_DETAILS_BY_UUID';
const QUERY_MANAGE_CASE_COMMENTS_BY_UUID = 'QUERY_MANAGE_CASE_COMMENTS_BY_UUID';
const QUERY_CASE_SMS_BY_UUID = 'QUERY_CASE_SMS_BY_UUID';
const QUERY_CASE_ATTACHABLE_BR_BY_UUID = 'QUERY_CASE_ATTACHABLE_BR_BY_UUID';

const selectorEventDetails = (data: EventDetailsDto): CaseEventData => ({
  promisedTimeArrival: convertToTime(data.promisedTimeArrival),
  dayPromisedTimeToArrival: convertUTCDateToString(data.promisedTimeArrival),
  promisedTimeArrivalPlusFiftyPercent: convertToTime(data.promisedTimeArrivalPlusFiftyPercent),
  dayPromisedTimeArrivalPlusFiftyPercent: convertUTCDateToString(
    data.promisedTimeArrivalPlusFiftyPercent,
  ),
  receivedOnLocation: data.receivedOnLocation || '',
  dispatchedOnLocation: data.dispatchedOnLocation || '',
  exclusionReason: data.exclusionReason || '',
  reunite: data.reunite || false,
  unAssociate: data.unAssociate || false,
  eventStatusHistory: data.eventStatusHistory || [],
});

const mapEventDetailsDataToDto = (data: Partial<CaseEventDetails>): Partial<EventDetailsDto> => ({
  ...data,
});

const mapCaseCreditCardDtoToData = (
  dto: CreditCardTransactionDto[],
): CreditCardData | undefined => {
  const [firstResult] = dto;

  if (!firstResult) {
    return;
  }

  return {
    ...firstResult,
    rapCaseId: firstResult.rbpCase.rapCaseId,
    rapProgramCode: firstResult.rbpCase.rapProgramCode,
    clientName: firstResult.rbpCase.clientName,
    programName: firstResult.rbpCase.programName,
    clubCode: firstResult.rbpCase.clubCode,
    rapCallCreatedDateTime: firstResult.rbpCase.rapCallCreatedDateTime,
    rbpCaseStatus: firstResult.rbpCase.rbpCaseStatus,
    rbpCaseUuid: firstResult.rbpCase.rbpCaseUuid,
  };
};

export function useGetCaseCreditCardByUUID(uuid: string, enabled: boolean) {
  return useQuery(
    [QUERY_MANAGE_CASE_CREDIT_CARD_BY_UUID, uuid],
    () => fetchCaseCreditCardByUUID(uuid),
    {
      keepPreviousData: true,
      enabled,
      select: mapCaseCreditCardDtoToData,
    },
  );
}

export function useGetCaseCreditCardReceiptByUUID(uuid: string, enabled: boolean) {
  return useQuery(
    [QUERY_MANAGE_CASE_CREDIT_CARD_RECEIPT_BY_UUID, uuid],
    () => fetchCaseCreditCardReceiptByUUID(uuid),
    {
      keepPreviousData: true,
      enabled,
    },
  );
}

export function useGetBreakdownLocationByUUID(caseId: string, enabled: boolean) {
  const { isLoading, isSuccess, data } = useQuery(
    [QUERY_BREAKDOWN_LOCATION_BY_UUID, caseId],
    () => fetchBreakdownLocationByUUID(caseId),
    { keepPreviousData: true, enabled },
  );
  return { isSuccess, data, isLoading };
}

export function useGetTowDestinationsByUUID(caseId: string, enabled: boolean) {
  const { isSuccess, data, isLoading } = useQuery(
    [QUERY_TOW_DESTINATIONS_BY_UUID, caseId],
    () => fetchTowDestinationsByUUID(caseId),
    { enabled },
  );
  return { isSuccess, data, isLoading };
}

export function useGetCaseErrorsByUUID(uuid: string, enabled: boolean) {
  return useQuery([QUERY_MANAGE_CASE_ERRORS_BY_UUID, uuid], () => fetchCaseErrorsByUUID(uuid), {
    keepPreviousData: true,
    enabled,
  });
}

export function useCaseErrorUpdate(uuid: string) {
  const queryClient = useQueryClient();
  return useManagedMutation({
    mutationFn: (update: Partial<CaseErrorDto>[]) => updateCaseErrorByUUID(uuid, update),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_MANAGE_CASE_ERRORS_BY_UUID, uuid]);
      queryClient.invalidateQueries([QUERY_CASES_BY_UUID]);
    },
  });
}

export function useGetCaseEventDetailsByUUID(uuid: string, enabled: boolean) {
  return useQuery(
    [QUERY_MANAGE_CASE_EVENT_DETAILS_BY_UUID, uuid],
    () => fetchCaseEventDetailsByUUID(uuid),
    { keepPreviousData: true, enabled, select: selectorEventDetails },
  );
}

export function useGetCaseCommentsByUUID(uuid: string, enabled: boolean) {
  return useQuery([QUERY_MANAGE_CASE_COMMENTS_BY_UUID, uuid], () => fetchCaseCommentsByUUID(uuid), {
    enabled,
    select: (comments) => orderBy(comments, ['createdAtDateTime'], ['asc']),
  });
}

export function useGetCaseSmsByUUID(uuid: string, enabled: boolean) {
  return useQuery([QUERY_CASE_SMS_BY_UUID, uuid], () => fetchCaseSmsByUUID(uuid), {
    enabled,
    select: (smsMessages) => orderBy(smsMessages, ['sentAt'], ['asc']),
  });
}

export function useUpdateCaseEventDetails(caseUuid: string) {
  const queryClient = useQueryClient();

  return useManagedMutation({
    mutationFn: (eventDetails: Partial<CaseEventDetails>) =>
      updateCaseEventDetails(caseUuid, mapEventDetailsDataToDto(eventDetails)),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_MANAGE_CASE_EVENT_DETAILS_BY_UUID]);
    },
  });
}

export function useAddUserComment(caseUuid: string) {
  const queryClient = useQueryClient();
  return useMutation((comment: BaseCommentDto) => addUserComment(caseUuid, comment), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_MANAGE_CASE_COMMENTS_BY_UUID, caseUuid]);
    },
  });
}

export function useAddAdjustmentCases(caseUuid: string) {
  const queryClient = useQueryClient();
  // TODO: type when BE define the contract and the endpoint path
  return useMutation((body: any) => addCaseAdjustment(caseUuid, body), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_MANAGE_CASE_COMMENTS_BY_UUID, caseUuid]);
    },
  });
}

export function useUpdateCaseStatus(caseUuid: string) {
  const queryClient = useQueryClient();

  return useManagedMutation({
    mutationFn: (rbpCaseStatus: RbpCaseStatus) => updateCaseStatus(caseUuid, rbpCaseStatus),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_CASES_BY_UUID]);
      queryClient.invalidateQueries([QUERY_MANAGE_CASE_ERRORS_BY_UUID, caseUuid]);
      queryClient.invalidateQueries(['QUERY_CASES_KEY_ALL']);
    },
  });
}

export function useUpdateCallStatus(caseUuid: string) {
  const queryClient = useQueryClient();

  return useManagedMutation({
    mutationFn: (callStatus: string) => updateCallStatus(caseUuid, callStatus),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_CASES_BY_UUID]);
      queryClient.invalidateQueries(['QUERY_CASES_KEY_ALL']);
      queryClient.refetchQueries([QUERY_CASES_BY_UUID]);
    },
  });
}

const mapBillingRequestDtoToData = (dtos: BillingRequestDto[]) => {
  const items: any = [];

  dtos?.forEach((item) => {
    items.push({
      label: `${item.number} - ${item.name}`,
      value: item.uuid,
    });
  });
  return items;
};

export const useGetCaseAttachableBillingRequests = (caseUuid: string, enabled: boolean) => {
  return useQuery(
    [QUERY_CASE_ATTACHABLE_BR_BY_UUID, caseUuid],
    () => fetchCaseAttachableBillingRequests(caseUuid),
    { enabled, select: mapBillingRequestDtoToData },
  );
};

export function useExecuteBulkAction() {
  const queryClient = useQueryClient();
  return useMutation((action: BulkActionDto) => executeBulkAction(action), {
    onSuccess: () => {
      queryClient.invalidateQueries(['QUERY_CASES_KEY_ALL']);
    },
  });
}

export function setRequestSearchByErrorCase(
  data: DynamicQueryData<CaseSearch>,
  CaseFields: DynamicQueryFieldType<CaseSearch>[],
  onlyClubCases?: boolean,
) {
  const fieldDef = CaseFields.find((item) => item.name === 'errorCode');
  const { errorCode, errorCodeStatus } = data;
  if (fieldDef && fieldDef.searchTransformer && errorCode) {
    const clubClause = onlyClubCases ? " AND errors.responsible: 'CLUB'" : '';

    let values = fieldDef.searchTransformer(errorCode);
    values = Array.isArray(values) ? values : [values];
    const errorQueries = values
      .map((code) => code.toString().trim())
      .map(
        (code) =>
          `(errors.code IN ('${code}') AND errors.resolutionType : '${errorCodeStatus}'${clubClause})`,
      )
      .join(' OR ');
    data.errorCodeStatus = '';
    data.errorCode = '';
    const otherClause = getQueryString(data, CaseFields);
    return `(${errorQueries}) ${otherClause ? 'AND ' + otherClause : ''}`;
  }
  return getQueryString(data, CaseFields);
}
